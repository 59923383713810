import React, { useContext } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Card,
  Center,
  Code,
  Flex,
  Heading,
  IconButton,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { CopyIcon, DeleteIcon } from "@chakra-ui/icons";

import { AdminContext } from "../../../shared/providers/admin-context";
import { getCompanyLink } from "../admin-page.utils";

export const CompaniesTabContent = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { companies, onRemoveCompany } = useContext(AdminContext);

  const cancelRef = React.useRef<any>();

  const handleCopyToClipboard = async (code: string) => {
    await navigator.clipboard.writeText(code);
    toast({
      title: "Copied to clipboard!",
      duration: 2000,
    });
  };

  return (
    <Center>
      <VStack gap={2}>
        <Heading mb={4}>Companies</Heading>
        {companies.length ? (
          <SimpleGrid columns={{ base: 1, lg: 2 }} gap={4}>
            {companies.map((company) => (
              <Card
                p={4}
                key={`${company.companyIdentifier}-${company.uuid}`}
                w="100%"
                sx={{ boxShadow: "none", border: "1px solid #e1e1e1" }}
              >
                <Stack>
                  <Flex justifyContent={"space-between"} alignItems="center">
                    <Text
                      fontSize="2xl"
                      variant={"primary"}
                      fontWeight={600}
                      wordBreak="break-word"
                    >
                      {company.companyIdentifier}
                    </Text>
                    <Tooltip label="Remove">
                      <IconButton
                        onClick={onOpen}
                        icon={<DeleteIcon />}
                        variant={"link-error"}
                        aria-label={"Remove company"}
                      />
                    </Tooltip>
                    <AlertDialog
                      motionPreset="slideInBottom"
                      leastDestructiveRef={cancelRef}
                      onClose={onClose}
                      isOpen={isOpen}
                      isCentered
                    >
                      <AlertDialogOverlay />

                      <AlertDialogContent>
                        <AlertDialogHeader>Remove company?</AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>
                          Are you sure you want to delete this company
                          identifier? All the informations about this company
                          will be removed.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                          <Button
                            ref={cancelRef}
                            variant={"outline"}
                            colorScheme="error"
                            onClick={onClose}
                          >
                            No
                          </Button>
                          <Button
                            colorScheme="error"
                            ml={3}
                            onClick={() => {
                              onRemoveCompany(company.uuid);
                              onClose();
                            }}
                          >
                            Yes
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </Flex>
                  <Code
                    p={3}
                    pl={5}
                    display="flex"
                    alignItems="center"
                    gap={2}
                    justifyContent="space-between"
                    wordBreak={"break-all"}
                  >
                    {getCompanyLink(company.uuid, company.code)}
                    <Tooltip label="Copy">
                      <IconButton
                        onClick={() =>
                          handleCopyToClipboard(
                            getCompanyLink(company.uuid, company.code)
                          )
                        }
                        aria-label="Copy"
                        variant="ghost"
                        icon={<CopyIcon />}
                      />
                    </Tooltip>
                  </Code>
                </Stack>
              </Card>
            ))}
          </SimpleGrid>
        ) : (
          <Text p={6}>
            No codes were generated yet. Please add companies by generating
            codes on the form above.
          </Text>
        )}
      </VStack>
    </Center>
  );
};

export default CompaniesTabContent;
