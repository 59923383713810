import {
  Box,
  Button,
  Card,
  Center,
  Flex,
  Heading,
  Image,
  Stack,
  Tag,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { formatDate, formatPeriod } from "../../../shared/utils";
import OpeningTextWithLabel from "../../company-page/components/OpeningTextWithLabel";
import {
  MAX_NUM_OF_TAGS_DISPLAYED,
  mergeKeyWords,
} from "../openings-page.utils";
import {
  detailsBtnStyle,
  openingCardStyle,
} from "../styles/openings-page-styles";
import { TOpening } from "../types";

export type OpeningCardProps = {
  opening: TOpening;
};

const OpeningsPageCard = ({ opening }: OpeningCardProps) => {
  const {
    id,
    title,
    startDate,
    openPositions,
    periodCount,
    periodType,
    company,
  } = opening;
  const { logo, name } = company;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/openings/${id}`);
  };

  const displayedTags = useBreakpointValue({
    base: 3,
    md: 5,
  });
  const tags = mergeKeyWords(opening);
  const displayedKeyWords = tags.slice(0, displayedTags);

  return (
    <Card sx={openingCardStyle}>
      <Flex flexDirection={{ base: "column", lg: "row" }} alignItems="center">
        {logo ? (
          <Image
            boxSize="70px"
            mr={{ base: 0, lg: 6 }}
            src={`data:image/jpeg;base64,${logo}`}
            alt={`${name} logo`}
            objectFit={"contain"}
          />
        ) : (
          <Center w="70px" h="70px" mr={{ base: 0, lg: 6 }}>
            <Text fontSize="sm" fontWeight={700}>
              {name}
            </Text>
          </Center>
        )}

        <Box textAlign={{ base: "center", lg: "initial" }}>
          <Heading
            size="md"
            color={`primaryText`}
            noOfLines={2}
            sx={{ wordBreak: "break-word" }}
          >
            {title}
          </Heading>
          <Flex
            mt={6}
            wrap="wrap"
            justify={{ base: "center", lg: "flex-start" }}
          >
            {displayedKeyWords.map((keyword, i) => (
              <Tag
                size="sm"
                variant="solid"
                mr="2"
                mb="2"
                key={`${keyword}${i}`}
              >
                {keyword}
              </Tag>
            ))}
            {tags.length > MAX_NUM_OF_TAGS_DISPLAYED ? <Text>...</Text> : null}
          </Flex>
        </Box>
      </Flex>
      <Flex h="100%" flexDirection={{ base: "column", lg: "row" }}>
        <Stack pt={5} direction="column">
          <OpeningTextWithLabel
            label="Start date:"
            info={formatDate(startDate)}
          />
          <OpeningTextWithLabel label="Open positions:" info={openPositions} />
          <OpeningTextWithLabel
            label="Duration:"
            info={formatPeriod(periodCount, periodType)}
          />
        </Stack>
        <Button onClick={handleClick} sx={detailsBtnStyle}>
          Details
        </Button>
      </Flex>
    </Card>
  );
};

export default OpeningsPageCard;
