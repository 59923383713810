import {
  Avatar,
  Flex,
  Spacer,
  Text,
  VStack,
  Link,
  Icon,
  Box,
} from "@chakra-ui/react";

import { TTrainer } from "../types";
import { AiFillLinkedin } from "react-icons/ai";
import { cvIconStyle } from "../styles/opening-page-styles";

const TrainerDetails = ({ trainer }: { trainer: TTrainer }) => {
  const { id, name, role, linkedinURL, avatar } = trainer;
  return (
    <VStack spacing={4} align="stretch" key={id} mb={6}>
      <Flex align="center">
        <Avatar
          name={name}
          src={`data:image/png;base64, ${avatar}`}
          color="white"
          bg="gray.400"
        />
        <Box ml={3}>
          <Text>{name}</Text>
          <Text fontSize="xs" color="gray.400">
            {role}
          </Text>
        </Box>

        <Spacer />
        {linkedinURL && (
          <Link href={linkedinURL} isExternal>
            <Icon as={AiFillLinkedin} boxSize="2em" sx={cvIconStyle} />
          </Link>
        )}
      </Flex>
    </VStack>
  );
};

export default TrainerDetails;
