import { Center, Flex, Image, Stack, Text, Icon, Link } from "@chakra-ui/react";
import AcLogo from "../../assets/acIasi.png";
import { MdLocationOn, MdPhone } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";

export const Footer = () => {
  return (
    <Center bg={"grayBg"} px={[4, 12, 12, 12, 40, 60]} py={6}>
      <Flex
        justifyContent={["center", "space-between"]}
        w="100%"
        flexDirection={["column", null, "row"]}
      >
        <Center gap={2}>
          <Link href="https://ac.tuiasi.ro/" isExternal>
            <Image
              src={AcLogo}
              alt="logo"
              boxSize={["60px", "80px"]}
              objectFit="contain"
              mb={[3, null, 0]}
            />
          </Link>
        </Center>
        <Stack direction="column" alignItems={["center", null, "flex-start"]}>
          <Stack direction="row" align="center">
            <Icon as={MdLocationOn} boxSize="6" />
            <Text fontSize="xs" textAlign="center">
              Str. Prof. dr. doc. Dimitrie Mangeron, nr. 27, Iasi, 700050
            </Text>
          </Stack>
          <Stack direction="row" align="center">
            <Icon as={MdPhone} boxSize="6" />
            <Text fontSize="xs">+40-232-701331</Text>
          </Stack>
          <Stack direction="row" align="center">
            <Link href="https://www.facebook.com/ac.tuiasi.ro/" isExternal>
              <Icon as={FaFacebookSquare} boxSize="6" />
            </Link>
            <Text fontSize="xs">Social media</Text>
          </Stack>
        </Stack>
      </Flex>
    </Center>
  );
};

export default Footer;
