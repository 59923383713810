import { useState } from "react";
import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Heading,
  InputGroup,
  InputRightElement,
  Input,
  Card,
  Image,
  SimpleGrid,
  Text,
  Skeleton,
  Spinner,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TCompany } from "./types";
import { headingGradientStyle } from "../openings-page/styles/openings-page-styles";
import { getCompanies } from "../../api/companies";
import { cardStyle, gridStyle } from "./styles/compaies-page-styles";

export const CompaniesPage = () => {
  const [companies, setCompanies] = useState<TCompany[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<TCompany[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const response = await getCompanies();
      if (response) {
        setCompanies(response.data);
        setLoading(false);
      }
    }
    fetchData();
    setFilteredCompanies(companies);
  }, []);

  useEffect(() => {
    let filtered = [...companies];
    if (companies.length > 0 && searchTerm) {
      filtered = companies.filter((company) => {
        const filteredByName = company.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase().trim());

        return filteredByName;
      });
    }
    setFilteredCompanies(filtered);
  }, [searchTerm, companies]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(e.target.value);
  };

  const handleClick = (companyId: string) => {
    navigate(`/companies/${companyId}`);
  };

  return (
    <Box minH="calc(100vh - 64px)">
      <Center sx={headingGradientStyle}>
        <Heading size="xl" color="white" mb={6} textAlign="center">
          Meet our partners
        </Heading>
        <InputGroup w="100%" maxW="700px">
          <InputRightElement
            pointerEvents="none"
            children={<SearchIcon color="gray.300" transform="scaleX(-1)" />}
          />
          <Input placeholder="Search..." onChange={handleSearch} />
        </InputGroup>
      </Center>
      <Center flexDirection="column">
        {companies.length > 0 ? (
          <SimpleGrid sx={gridStyle} columns={[1, 2, 3, 4]}>
            {filteredCompanies.map((company: TCompany) => (
              <Card
                sx={cardStyle}
                key={company.id}
                onClick={() => {
                  handleClick(company.id);
                }}
              >
                {company.logo ? (
                  <Image
                    boxSize="100px"
                    src={`data:image/jpeg;base64,${company.logo}`}
                    alt={`${company.name} logo`}
                    objectFit={"contain"}
                  />
                ) : (
                  <Heading
                    size="md"
                    textAlign="center"
                    noOfLines={3}
                    sx={{ wordBreak: "break-word" }}
                  >
                    {company.name}
                  </Heading>
                )}
              </Card>
            ))}
          </SimpleGrid>
        ) : (
          <>
            {loading ? (
              <Spinner size="xl" mt={6} />
            ) : (
              <Text p={12} textAlign="center">
                There are no companies with available openings for the moment.
              </Text>
            )}
          </>
        )}

        {companies.length > 0 &&
          filteredCompanies.length === 0 &&
          searchTerm && (
            <Text>
              We couldn't find any matches for "{searchTerm}". Please try a
              different keyword.
            </Text>
          )}
      </Center>
    </Box>
  );
};

export default CompaniesPage;
