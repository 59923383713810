import { ChevronDownIcon, SmallAddIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalContent,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  VStack,
  Box,
} from "@chakra-ui/react";
import { FieldArray, Formik } from "formik";
import { useContext, useRef, useState } from "react";
import FormInputField from "../../../components/form-input-field/FormInputField";
import FormNumberInputField from "../../../components/form-number-input-field/FormNumberInputField";
import FormTextarea from "../../../components/form-textarea/FormTextarea";
import { CompanyAdminContext } from "../../../shared/providers/company-admin-context";
import {
  keyWords,
  openingFormValidationSchema,
  periodTypes,
  trainerInitialValues,
  setInputMaxCharNo,
  TITLE_MAX_LENGTH,
  TEXT_AREA_MAX_LENGTH,
  RECRUITMENT_PROCESS_MAX_LENGTH,
} from "../company-admin-utils";
import { AddOpeningModalProps } from "../types";
import TrainerCard from "./TrainerCard";

export const AddOpeningModal = ({ isOpen, onClose }: AddOpeningModalProps) => {
  const { selectedOpening, handleSubmitOpening } =
    useContext(CompanyAdminContext);

  const [customKeyWordValue, setCustomKeyWordValue] = useState<string>("");

  const initialRef = useRef(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      motionPreset="slideInBottom"
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent py={10} px={[4, 10]}>
        <Stack gap={6}>
          <Heading as={"h4"} size="xl">
            {selectedOpening?.id === "new-opening"
              ? "New opening"
              : `Edit "${selectedOpening?.title}"`}
          </Heading>
          <Divider />
          {selectedOpening && (
            <Formik
              initialValues={selectedOpening}
              onSubmit={handleSubmitOpening}
              validationSchema={openingFormValidationSchema}
              validateOnBlur
            >
              {({
                values,
                handleChange,
                setFieldValue,
                handleSubmit,
                handleBlur,
                errors,
                touched,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Stack gap={6}>
                      {/* title */}
                      <FormInputField
                        placeholder="title"
                        label="Title"
                        name="title"
                        value={values.title}
                        handleChange={(e) => {
                          const fieldValue = setInputMaxCharNo(
                            e.target.value,
                            TITLE_MAX_LENGTH
                          );
                          setFieldValue("title", fieldValue);
                        }}
                        onBlur={handleBlur}
                        forwardedRef={initialRef}
                        isRequired
                        isInvalid={!!(errors.title && touched.title)}
                        errorMessage={errors.title}
                      />
                      {/* description */}
                      <Stack
                        w="100%"
                        gap={6}
                        direction={["column", null, "row"]}
                      >
                        <FormTextarea
                          placeholder="short description"
                          label="Short description"
                          name="description"
                          isRequired
                          value={values.description}
                          handleChange={(e) => {
                            const fieldValue = setInputMaxCharNo(
                              e.target.value,
                              TEXT_AREA_MAX_LENGTH
                            );
                            setFieldValue("description", fieldValue);
                          }}
                          onBlur={handleBlur}
                          isInvalid={
                            !!(errors.description && touched.description)
                          }
                          errorMessage={errors.description}
                        />
                        <FormTextarea
                          placeholder="requirements"
                          label="Requirements"
                          isRequired
                          name="requirements"
                          value={values.requirements}
                          handleChange={(e) => {
                            const fieldValue = setInputMaxCharNo(
                              e.target.value,
                              TEXT_AREA_MAX_LENGTH
                            );
                            setFieldValue("requirements", fieldValue);
                          }}
                          onBlur={handleBlur}
                          isInvalid={
                            !!(errors.requirements && touched.requirements)
                          }
                          errorMessage={errors.requirements}
                        />
                      </Stack>

                      <Stack
                        w="100%"
                        gap={6}
                        direction={["column", null, "row"]}
                      >
                        <FormTextarea
                          placeholder="recruitment process"
                          label="Recruitment process"
                          name="recruitmentProcess"
                          value={values.recruitmentProcess}
                          handleChange={(e) => {
                            const fieldValue = setInputMaxCharNo(
                              e.target.value,
                              RECRUITMENT_PROCESS_MAX_LENGTH
                            );
                            setFieldValue("recruitmentProcess", fieldValue);
                          }}
                          onBlur={handleBlur}
                          isRequired
                          isInvalid={
                            !!(
                              errors.recruitmentProcess &&
                              touched.recruitmentProcess
                            )
                          }
                          errorMessage={errors.recruitmentProcess}
                        />
                        {/* restrictions */}
                        <FormTextarea
                          placeholder="restrictions"
                          label="Restrictions"
                          name="restrictions"
                          isRequired
                          value={values.restrictions}
                          handleChange={(e) => {
                            const fieldValue = setInputMaxCharNo(
                              e.target.value,
                              TEXT_AREA_MAX_LENGTH
                            );
                            setFieldValue("restrictions", fieldValue);
                          }}
                          onBlur={handleBlur}
                          isInvalid={
                            !!(errors.restrictions && touched.restrictions)
                          }
                          errorMessage={errors.restrictions}
                        />
                      </Stack>
                      {/* interview/test checks */}
                      <Stack w="100%" gap={6}>
                        <Stack spacing={6} direction={["column", "row"]}>
                          <Checkbox
                            name="hasTechnicalInterview"
                            onChange={handleChange}
                          >
                            Technical interview?
                          </Checkbox>
                          <Checkbox
                            name="hasTechnicalTest"
                            onChange={handleChange}
                          >
                            Technical test?
                          </Checkbox>
                        </Stack>
                      </Stack>

                      {/*start date, period */}
                      <Stack
                        gap={6}
                        direction={["column", "row"]}
                        flexWrap="wrap"
                      >
                        <FormInputField
                          placeholder="start date"
                          label="Start date"
                          type="date"
                          name="startDate"
                          value={values.startDate}
                          handleChange={handleChange}
                          onBlur={handleBlur}
                          isRequired
                          isInvalid={!!(errors.startDate && touched.startDate)}
                          errorMessage={errors.startDate}
                          maxW="230px"
                        />

                        <Stack spacing={2}>
                          <Text
                            ml={1}
                            fontSize="sm"
                            variant="primary"
                            fontWeight={500}
                          >
                            Period
                          </Text>
                          <HStack>
                            <NumberInput
                              min={1}
                              max={50}
                              name="periodCount"
                              defaultValue={values.periodCount}
                              value={values.periodCount}
                              onChange={(valAsString, valAsNumber) =>
                                setFieldValue("periodCount", valAsNumber)
                              }
                              onBlur={handleBlur}
                              isInvalid={
                                !!(errors.periodCount && touched.periodCount)
                              }
                              errorBorderColor="error.500"
                              w="85px"
                            >
                              <NumberInputField pl={3} />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>

                            <Select
                              size="md"
                              name="periodType"
                              value={values.periodType}
                              onChange={handleChange}
                              rootProps={{ maxW: "130px" }}
                            >
                              {periodTypes.map((periodType) => (
                                <option key={periodType}>{periodType}</option>
                              ))}
                            </Select>
                          </HStack>
                        </Stack>
                        <FormNumberInputField
                          label="No. of open positions"
                          name="openPositions"
                          value={values.openPositions}
                          defaultValue={values.openPositions}
                          handleChange={(valAsString, valAsNumber) =>
                            setFieldValue("openPositions", valAsNumber)
                          }
                          min={1}
                          max={50}
                          isInvalid={
                            !!(errors.openPositions && touched.openPositions)
                          }
                          errorMessage={errors.openPositions}
                        />
                      </Stack>

                      {/*key words  */}

                      <Stack
                        w="100%"
                        gap={6}
                        direction={["column", null, "row"]}
                      >
                        <FormControl
                          isInvalid={!!(errors.keyWords && touched.keyWords)}
                        >
                          <Stack spacing={2} w="100%">
                            <Text
                              ml={1}
                              fontSize="sm"
                              variant="primary"
                              fontWeight={500}
                              _after={{
                                content: '"*"',
                                color: "red.500",
                                marginLeft: 1,
                              }}
                            >
                              Key words
                            </Text>
                            <Box>
                              <Menu closeOnSelect={false} isLazy>
                                {({ onClose }) => (
                                  <>
                                    <MenuButton
                                      maxW={"200px"}
                                      as={Button}
                                      rightIcon={<ChevronDownIcon />}
                                      variant="outline-secondary"
                                      color={"darkText"}
                                      borderColor={"grayBg"}
                                      p={2}
                                      px={4}
                                      borderRadius="4px"
                                    >
                                      Select key words
                                    </MenuButton>

                                    <MenuList maxH="400px" overflow={"auto"}>
                                      <MenuGroup title={undefined}>
                                        <MenuItem
                                          onClick={() => {
                                            setFieldValue("keyWords", []);
                                            onClose();
                                          }}
                                        >
                                          Clear all
                                        </MenuItem>
                                      </MenuGroup>
                                      <MenuDivider />
                                      <MenuOptionGroup
                                        title={undefined}
                                        value={values.keyWords}
                                        type="checkbox"
                                        onChange={(
                                          selectValues: string | string[]
                                        ) => {
                                          setFieldValue(
                                            "keyWords",
                                            selectValues
                                          );
                                        }}
                                      >
                                        {keyWords.map((keyWord) => (
                                          <MenuItemOption
                                            key={keyWord}
                                            value={keyWord}
                                          >
                                            {keyWord}
                                          </MenuItemOption>
                                        ))}
                                      </MenuOptionGroup>
                                    </MenuList>
                                  </>
                                )}
                              </Menu>
                            </Box>
                            <Flex gap={2} flexWrap="wrap">
                              {values.keyWords.map((selectedKeyWord) => (
                                <Tag size={"md"} key={selectedKeyWord}>
                                  <TagLabel>{selectedKeyWord}</TagLabel>
                                  <TagCloseButton
                                    onClick={() =>
                                      setFieldValue(
                                        "keyWords",
                                        values.keyWords.filter(
                                          (kw) => kw !== selectedKeyWord
                                        )
                                      )
                                    }
                                  />
                                </Tag>
                              ))}
                            </Flex>
                          </Stack>
                          {errors.keyWords && (
                            <FormErrorMessage color="error.500">
                              {errors.keyWords.toString()}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <Stack spacing={2} w="100%">
                          <Text
                            ml={1}
                            fontSize="sm"
                            variant="primary"
                            fontWeight={500}
                          >
                            Custom key words
                          </Text>
                          <InputGroup size="md">
                            <Input
                              placeholder="add custom key words"
                              value={customKeyWordValue}
                              onChange={(ev) =>
                                setCustomKeyWordValue(ev.target.value)
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  if (
                                    customKeyWordValue &&
                                    customKeyWordValue.trim().length
                                  ) {
                                    setFieldValue("customKeyWords", [
                                      ...values.customKeyWords,
                                      customKeyWordValue,
                                    ]);
                                    setCustomKeyWordValue("");
                                  }
                                }
                              }}
                            />
                            <InputRightElement width="4.5rem">
                              <Button
                                h="1.75rem"
                                size="sm"
                                onClick={() => {
                                  const newVal = customKeyWordValue?.trim();
                                  if (newVal.length) {
                                    if (
                                      !values.customKeyWords.includes(newVal)
                                    ) {
                                      setFieldValue("customKeyWords", [
                                        ...values.customKeyWords,
                                        customKeyWordValue,
                                      ]);
                                    }
                                    setCustomKeyWordValue("");
                                  }
                                }}
                              >
                                {"Add"}
                              </Button>
                            </InputRightElement>
                          </InputGroup>

                          <Flex gap={2} flexWrap="wrap">
                            {values.customKeyWords.map((selectedKeyWord) => (
                              <Tag size={"md"} key={selectedKeyWord}>
                                <TagLabel>{selectedKeyWord}</TagLabel>
                                <TagCloseButton
                                  onClick={() =>
                                    setFieldValue(
                                      "customKeyWords",
                                      values.customKeyWords.filter(
                                        (kw) => kw !== selectedKeyWord
                                      )
                                    )
                                  }
                                />
                              </Tag>
                            ))}
                          </Flex>
                        </Stack>
                      </Stack>

                      {/* trainers */}
                      <Stack w="100%" gap={2}>
                        <Text
                          ml={1}
                          fontSize="sm"
                          variant="primary"
                          fontWeight={500}
                          _after={{
                            content: '"*"',
                            color: "red.500",
                            marginLeft: 1,
                          }}
                        >
                          Trainers
                        </Text>
                        <FormControl
                          isInvalid={!!(errors.trainers && touched.trainers)}
                        >
                          <FieldArray
                            name="trainers"
                            render={(arrayHelpers) => (
                              <Stack gap={4}>
                                {values.trainers.map((trainer, i) => (
                                  <TrainerCard
                                    key={trainer.id}
                                    index={i}
                                    onRemoveTrainer={() =>
                                      arrayHelpers.remove(i)
                                    }
                                  />
                                ))}
                                <Flex>
                                  <Button
                                    onClick={() =>
                                      arrayHelpers.push({
                                        ...trainerInitialValues,
                                        id: values.trainers.length,
                                      })
                                    }
                                    variant={"outline"}
                                    leftIcon={<SmallAddIcon />}
                                  >
                                    Add trainer
                                  </Button>
                                </Flex>
                                {errors.trainers &&
                                  typeof errors.trainers === "string" && (
                                    <FormErrorMessage color="error.500">
                                      {errors.trainers}
                                    </FormErrorMessage>
                                  )}
                              </Stack>
                            )}
                          />
                        </FormControl>
                      </Stack>

                      {/* agreements */}
                      <VStack alignItems="flex-start">
                        <FormControl
                          isRequired
                          isInvalid={!!errors.acceptOnClosingOpportunity}
                        >
                          <Checkbox
                            name="acceptOnClosingOpportunity"
                            checked={values.acceptOnClosingOpportunity}
                            defaultChecked={values.acceptOnClosingOpportunity}
                            onChange={handleChange}
                          >
                            Accept on closing opportunity after student agree
                          </Checkbox>

                          {errors.acceptOnClosingOpportunity && (
                            <FormErrorMessage color="error.500">
                              {errors.acceptOnClosingOpportunity}
                            </FormErrorMessage>
                          )}
                        </FormControl>

                        <FormControl
                          isRequired
                          isInvalid={!!errors.signAgreement}
                        >
                          <Checkbox
                            name="signAgreement"
                            checked={values.signAgreement}
                            defaultChecked={values.signAgreement}
                            onChange={handleChange}
                          >
                            Sign agreement with Faculty of Automation and
                            Computers
                          </Checkbox>
                          {errors.signAgreement && (
                            <FormErrorMessage color="error.500">
                              {errors.signAgreement}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </VStack>
                      <Divider />
                      <Flex justifyContent="flex-end" w="100%">
                        <Button type="submit">
                          {selectedOpening?.id === "new-opening"
                            ? "Submit"
                            : "Save changes"}
                        </Button>
                      </Flex>
                    </Stack>
                  </form>
                );
              }}
            </Formik>
          )}
        </Stack>
      </ModalContent>
    </Modal>
  );
};

export default AddOpeningModal;
