export const HomeIllustration = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="400px"
    height="390px"
    viewBox="0 0 726.42275 705.12037"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M745.61757,799.92245c-.76025.8-1.55029,1.59-2.3501,2.38H652.04726c-.75-.77-1.48975-1.56-2.23-2.38-.02979-.03-.06006-.07-.08984-.1-9.91016-10.98-16.14991-26.93994-20.04-43.77-.25-1.02-.46973-2.05-.68994-3.09-1.02-4.73-1.8501-9.51-2.52979-14.25-.12012-.82-.24023-1.64-.34033-2.46-.91992-6.81-1.54-13.51-1.93994-19.80005-.04981-.81-.1001-1.6-.14991-2.39a324.8295,324.8295,0,0,1-.02-37.6s.12012,0,.3501.01c.66992.03,2.27979.1,4.6499.24,6.52979.4,18.8501,1.4,33.33985,3.76.79.13,1.59033.27,2.39014.41a252.14865,252.14865,0,0,1,36.16992,8.76c.74023.25,1.47021.5,2.21.75l.01025.05,9.06983,45.51,3.76025-40.51c2.98975,1.35,5.8999,2.79,8.68994,4.33.77979.43,1.54.86005,2.29,1.3a72.11071,72.11071,0,0,1,17.16992,13.54C773.86757,747.58242,767.49746,777.05245,745.61757,799.92245Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#f2f2f2"
    />
    <path
      d="M707.49693,742.2145l1.5815,1.75191c18.27934,20.45824,29.18828,39.44763,32.54919,56.71924.05337.2509.09022.501.14354.75191l-1.16948.22151-1.127.19878c-3.43017-18.83043-16.201-38.29556-32.14012-56.144-.50808-.58029-1.024-1.1692-1.55719-1.74244-6.81415-7.496-14.14013-14.68731-21.548-21.41127-.5688-.52547-1.15407-1.05174-1.73934-1.578-9.82363-8.82461-19.70679-16.80843-28.52071-23.53807-.62084-.4785-1.25031-.94911-1.872-1.41109-14.96533-11.32935-26.57692-18.839-29.1649-20.48522-.312-.20626-.48855-.3145-.52853-.34134l.61254-.978.00084-.01652.62122-.98582c.04.02689.63374.38817,1.70647,1.08831,4.02842,2.59673,14.85613,9.74364,28.3259,19.90458.60516.46114,1.23468.9318,1.85547,1.41025,7.1584,5.45372,14.98763,11.70282,22.89414,18.54316q2.9832,2.57238,5.8179,5.137c.5939.51848,1.17917,1.04475,1.748,1.57022Q697.99967,731.78093,707.49693,742.2145Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#fff"
    />
    <path
      d="M663.21767,678.53243c-.27.65-.56006,1.3-.86035,1.94a62.38191,62.38191,0,0,1-8.68994,13.75c-.50977.6-1.02979,1.21-1.56983,1.81q-2.18994,2.415-4.66015,4.67a62.59536,62.59536,0,0,1-23.39991,13.36c-.28027.09-.5498.18-.83007.26.0498.79.10009,1.57.1499,2.38.28027-.08.55029-.16.83008-.25a64.84908,64.84908,0,0,0,24.81006-14.02,66.93793,66.93793,0,0,0,4.97021-4.99c.53955-.59,1.06006-1.2,1.56006-1.81a64.419,64.419,0,0,0,9.21973-14.75c.28027-.64.56-1.28.83007-1.93005C664.77773,678.81246,663.98769,678.67245,663.21767,678.53243Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#fff"
    />
    <path
      d="M701.32753,687.87246c-.12011.59-.26025,1.18-.41015,1.77a62.56419,62.56419,0,0,1-16.67969,29.67c-.57031.57-1.15039,1.13-1.75,1.67-.17041.17-.36035.35-.54.51a63.02385,63.02385,0,0,1-55.82031,14.76c-.23-.04-.46973-.1-.69971-.15.11963.82.23,1.65.35986,2.47.23.05005.4502.1.68018.14a65.35912,65.35912,0,0,0,57.03955-15.49c.24024-.22.49024-.43.72022-.66.60009-.56,1.18017-1.12,1.76025-1.68a65.0285,65.0285,0,0,0,17.1499-30.44q.22485-.90006.41992-1.8C702.81728,688.38247,702.07753,688.12246,701.32753,687.87246Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#fff"
    />
    <path
      d="M724.65761,698.08242c.00977.56.00977,1.13,0,1.69a62.703,62.703,0,0,1-17.16015,42.44c-.56006.6-1.14014,1.18-1.71973,1.76-.63037.61-1.26026,1.22-1.91016,1.8a63.16193,63.16193,0,0,1-74.87011,7.19,6.71349,6.71349,0,0,1-.58008-.34c.24023,1.06.48,2.1.74023,3.13995.17969.1.34961.2.52979.29a65.50483,65.50483,0,0,0,75.74023-8.54c.6499-.59,1.29981-1.18,1.90967-1.8.6001-.57,1.16992-1.15,1.74023-1.75a65.25122,65.25122,0,0,0,17.87012-42.89q.03-.81.02979-1.62C726.21767,698.98245,725.4374,698.53243,724.65761,698.08242Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#fff"
    />
    <path
      d="M522.49219,262.76025H346.70654a5.52372,5.52372,0,0,1-5.51758-5.51757V123.76758a5.52415,5.52415,0,0,1,5.51758-5.51807H562.47461a5.52415,5.52415,0,0,1,5.51758,5.51807v93.49267A45.55143,45.55143,0,0,1,522.49219,262.76025Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#fff"
    />
    <path
      d="M522.49219,263.26025H346.70654a6.02469,6.02469,0,0,1-6.01758-6.01757V123.76758a6.0248,6.0248,0,0,1,6.01758-6.01807H562.47461a6.02449,6.02449,0,0,1,6.01758,6.01807v93.49267A46.05239,46.05239,0,0,1,522.49219,263.26025ZM346.70654,119.74951a4.02251,4.02251,0,0,0-4.01758,4.01807v133.4751a4.02208,4.02208,0,0,0,4.01758,4.01757H522.49219a44.04978,44.04978,0,0,0,44-44V123.76758a4.02251,4.02251,0,0,0-4.01758-4.01807Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#ccc"
    />
    <path
      d="M519.82276,163.32654H389.35839a4.51607,4.51607,0,0,1,0-9.03215H519.82276a4.51608,4.51608,0,0,1,0,9.03215Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#ccc"
    />
    <path
      d="M519.82276,194.43727H389.35839a4.51607,4.51607,0,0,1,0-9.03214H519.82276a4.51607,4.51607,0,1,1,0,9.03214Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#ccc"
    />
    <path
      d="M519.82276,226.43727H389.35839a4.51607,4.51607,0,0,1,0-9.03214H519.82276a4.51607,4.51607,0,1,1,0,9.03214Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#ccc"
    />
    <circle cx="325.02994" cy="26.9685" r="26.96851" fill="#2962ff" />
    <path
      d="M557.17559,138.18644a2.999,2.999,0,0,1-2.39966-1.2002l-7.35742-9.81005a2.9998,2.9998,0,1,1,4.7998-3.59961l4.81348,6.41748,12.36279-18.54395a2.99993,2.99993,0,1,1,4.99219,3.32813L559.67192,136.8505a3.00113,3.00113,0,0,1-2.41284,1.335C557.23125,138.186,557.20342,138.18644,557.17559,138.18644Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#fff"
    />
    <path
      d="M457.61035,378.68945H281.82471a45.55142,45.55142,0,0,1-45.5-45.5V239.69629a5.52456,5.52456,0,0,1,5.51806-5.51807H457.61035a5.52457,5.52457,0,0,1,5.51807,5.51807v133.4751A5.52456,5.52456,0,0,1,457.61035,378.68945Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#fff"
    />
    <path
      d="M457.61035,379.18945H281.82471a46.05239,46.05239,0,0,1-46-46V239.69629a6.0249,6.0249,0,0,1,6.01806-6.01807H457.61035a6.02491,6.02491,0,0,1,6.01807,6.01807v133.4751A6.0249,6.0249,0,0,1,457.61035,379.18945ZM241.84277,235.67822a4.02292,4.02292,0,0,0-4.01806,4.01807v93.49316a44.04978,44.04978,0,0,0,44,44H457.61035a4.02261,4.02261,0,0,0,4.01807-4.01806V239.69629a4.02293,4.02293,0,0,0-4.01807-4.01807Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#ccc"
    />
    <path
      d="M414.95874,279.25533H284.49438a4.51607,4.51607,0,1,1,0-9.03214H414.95874a4.51607,4.51607,0,1,1,0,9.03214Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#ccc"
    />
    <path
      d="M414.95874,310.36607H284.49438a4.51607,4.51607,0,0,1,0-9.03215H414.95874a4.51607,4.51607,0,1,1,0,9.03215Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#ccc"
    />
    <path
      d="M414.95874,342.36607H284.49438a4.51607,4.51607,0,0,1,0-9.03215H414.95874a4.51607,4.51607,0,1,1,0,9.03215Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#ccc"
    />
    <circle cx="222.02994" cy="143.9685" r="26.96851" fill="#2962ff" />
    <path
      d="M454.17559,255.18644a2.999,2.999,0,0,1-2.39966-1.2002l-7.35742-9.81005a2.9998,2.9998,0,1,1,4.7998-3.59961l4.81348,6.41748,12.36279-18.54395a2.99993,2.99993,0,0,1,4.99219,3.32813L456.67192,253.8505a3.00113,3.00113,0,0,1-2.41284,1.335C454.23125,255.186,454.20342,255.18644,454.17559,255.18644Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#fff"
    />
    <path
      d="M676.63477,503.10254H430.6709a45.55142,45.55142,0,0,1-45.5-45.5V320.08887a7.121,7.121,0,0,1,7.11279-7.1128H676.63477a7.1206,7.1206,0,0,1,7.1123,7.1128V495.98926A7.121,7.121,0,0,1,676.63477,503.10254Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#fff"
    />
    <path
      d="M676.63477,503.60254H430.6709a46.05239,46.05239,0,0,1-46-46V320.08887a7.62136,7.62136,0,0,1,7.61279-7.6128H676.63477a7.62125,7.62125,0,0,1,7.6123,7.6128V495.98926A7.62136,7.62136,0,0,1,676.63477,503.60254ZM392.28369,314.47607a5.61938,5.61938,0,0,0-5.61279,5.6128V457.60254a44.04978,44.04978,0,0,0,44,44H676.63477a5.61938,5.61938,0,0,0,5.6123-5.61328V320.08887a5.619,5.619,0,0,0-5.6123-5.6128Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#ccc"
    />
    <path
      d="M620.4257,372.22184H448.49249a5.95154,5.95154,0,1,1,0-11.90307H620.4257a5.95154,5.95154,0,1,1,0,11.90307Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#ccc"
    />
    <path
      d="M620.4257,413.2213H448.49249a5.95154,5.95154,0,1,1,0-11.90307H620.4257a5.95153,5.95153,0,1,1,0,11.90307Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#ccc"
    />
    <path
      d="M620.4257,455.39268H448.49249a5.95153,5.95153,0,1,1,0-11.90307H620.4257a5.95154,5.95154,0,1,1,0,11.90307Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#ccc"
    />
    <circle cx="441.70332" cy="226.5592" r="34.13884" fill="#2962ff" />
    <path
      d="M672.87078,341.509a3.79638,3.79638,0,0,1-3.03767-1.5193l-9.3136-12.41834a3.79739,3.79739,0,1,1,6.076-4.55667l6.09327,8.12375L688.33854,307.664a3.79755,3.79755,0,0,1,6.3195,4.213l-18.6272,27.94079a3.79906,3.79906,0,0,1-3.05436,1.6899C672.94125,341.50834,672.906,341.509,672.87078,341.509Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#fff"
    />
    <polygon
      points="530.145 677.866 516.256 682.511 491.729 631.148 512.229 624.292 530.145 677.866"
      fill="#ffb6b6"
    />
    <path
      d="M774.01508,787.32657l-44.78536,11.9776-.18946-.56645c-1.55706-4.6562-1.208-6.64056.98371-11.03388a18.26066,18.26066,0,0,1,10.61753-9.22769l.0011-.00037,6.10474-8.94234,17.33917,1.10206,3.90949-1.30744Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#445892"
    />
    <polygon
      points="564.461 690.925 549.816 690.925 542.848 634.435 564.464 634.436 564.461 690.925"
      fill="#ffb6b6"
    />
    <path
      d="M804.02078,799.30417l-47.22348-.00175v-.5973c.00029-4.90964,1.91235-6.52589,5.38445-9.99711a18.26064,18.26064,0,0,1,12.99624-5.38329h.00117l8.626-6.54413,16.09418,6.54513,4.12232.00017Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#445892"
    />
    <path
      d="M688.824,335.49809a10.6963,10.6963,0,0,0,14.36963,7.9072l16.90763,17.65192,13.233-7.65842-24.22695-24.59257a10.75429,10.75429,0,0,0-20.28331,6.69187Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#ffb6b6"
    />
    <polygon
      points="562.421 327.072 570.043 319.186 525.965 278.363 481.602 238.751 469.315 256.113 515.804 299.507 562.421 327.072"
      fill="#e4e4e4"
    />
    <path
      d="M771.16255,692.15579c0-9.91059.1962-16.97938.35372-22.65975.46114-16.62345.585-21.09175-5.77636-49.71884l-1.63393-7.35177-9.63462,28.90366a90.54051,90.54051,0,0,0-4.65708,28.59583l14.85545,89.1319H734.3073L712.07022,662.6966l-.01042-29.47522a10.87342,10.87342,0,0,1,0-15.11238l7.38785-44.15872,7.47782-59.51319h81.90719s14.45524,29.96182.09,59.68234l-.0016,118.07323-7.42191,81.63984H778.58607Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#445892"
    />
    <path
      d="M803.82486,521.64056l-76.613-6.3842-.39781-.03286,6.11405-110.05371a34.61141,34.61141,0,0,1,23.45877-30.98589v-7.70169a7.198,7.198,0,0,1,7.19-7.18984h11.194a7.15113,7.15113,0,0,1,6.4309,3.9745l5.47374,10.94749,20.69855,16.55892c10.223,8.17865,1.99506,90.17642,1.55276,94.50283l5.006,25.031a9.50513,9.50513,0,0,1-9.29653,11.368Q804.2329,521.67383,803.82486,521.64056Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#e4e4e4"
    />
    <path
      d="M722.6923,550.69888a10.69629,10.69629,0,0,0,.20244-16.40029l7.60124-23.231-12.99078-8.06246-10.27365,32.95742a10.7543,10.7543,0,0,0,15.46075,14.73632Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#ffb6b6"
    />
    <polygon
      points="513.585 295.588 503.038 292.072 487.804 360.04 473.773 427.634 494.876 430.739 511.241 358.868 513.585 295.588"
      fill="#e4e4e4"
    />
    <circle cx="525.10302" cy="229.91361" r="25.16214" fill="#ffb6b6" />
    <path
      d="M727.072,314.77327a18.89379,18.89379,0,0,1,2.0137-6.83272c1.1764,2.55786,4.84547,3.00167,7.35658,1.72862,2.51152-1.27309,4.19414-3.691,5.9886-5.86028,4.73564-5.72421,11.2481-10.36746,18.62873-11.21318,7.381-.84568,15.5164,2.88,18.30528,9.76583,1.25134-1.73769,3.92158-1.93088,5.81023-.92112a10.45018,10.45018,0,0,1,4.0759,4.82878,30.766,30.766,0,0,1,1.02328,24.61653c-3.10793,7.86471-10.78276,18.46094-18.70128,21.42923-4.80739,1.80212-9.66288-.50442-14.77569,0,.26775-5.57981,1.34955-12.46448,1.61729-18.04429.1467-3.05577.168-6.47458-1.9191-8.71139a6.02518,6.02518,0,0,0-9.19152,1.07294,13.84224,13.84224,0,0,0-1.72632-4.921,4.708,4.708,0,0,0-4.4198-2.26421c-2.13474.34987-3.45343,2.54128-5.41262,3.459a5.8822,5.8822,0,0,1-6.53731-1.49925A8.8943,8.8943,0,0,1,727.072,314.77327Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#2f2e41"
    />
    <polygon
      points="528.36 358.563 498.809 417.665 520.973 417.665 528.36 358.563"
      opacity="0.1"
    />
    <path
      d="M962.24746,801.11245a1.18633,1.18633,0,0,1-1.19,1.19h-564.29a1.19,1.19,0,0,1,0-2.38h564.29A1.18629,1.18629,0,0,1,962.24746,801.11245Z"
      transform="translate(-235.82471 -97.18208)"
      fill="#cacaca"
    />
  </svg>
);
