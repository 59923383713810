import {
  Center,
  Box,
  Flex,
  GridItem,
  Stack,
  Text,
  HStack,
  Button,
  Card,
  Image,
  Grid,
} from "@chakra-ui/react";
import React from "react";
import { PageNotFoundIllustration } from "../../assets/PageNotFoundIllustration";

import { useNavigate } from "react-router-dom";

export const Page404 = () => {
  const navigate = useNavigate();
  return (
    <Center
      sx={{
        h: "calc(100vh - 64px)",
        bg: "darkGrayBackground",
        bgGradient:
          "linear-gradient(90deg, rgba(23,24,27,1) 0%, rgba(44,45,51,1) 68%)",
        py: 20,
        px: [4, 12, 12, 12, 40, 60],
      }}
    >
      <Flex alignItems={"center"} gap={20}>
        <Stack gap={10} h={"100%"} justifyContent="center">
          <Text fontSize={{ base: "4xl", md: "5xl" }} color="white">
            Page you're looking for doesn't exist.
          </Text>
          <Text fontSize={{ base: "xl", md: "2xl" }} color="white">
            Check the URL again or go to Home Page.
          </Text>

          <Flex>
            <Button onClick={() => navigate("/")}>Go to home page</Button>
          </Flex>
        </Stack>
        <Box
          display={["none", null, null, "flex"]}
          maxW={["300px", null, null, null, "500px"]}
        >
          <PageNotFoundIllustration />
        </Box>
      </Flex>
    </Center>
  );
};

export default Page404;
