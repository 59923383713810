import { CompanyResponseType, TCompanyCode } from "./types"

export const getCompanyLink = (uuid: string, code: number) =>
    `${process.env.REACT_APP_COMPANY_ADMIN_URL}/company-admin/?uuid=${uuid}&code=${code}`

export const mapResponseToCompanies = (data: CompanyResponseType[]): TCompanyCode[] => {

    return data.map(company => ({ 
        code: company.accessCode.value, 
        uuid: company.id, 
        companyIdentifier: company.name
    }))

}
