import { CheckCircleIcon, SmallAddIcon } from "@chakra-ui/icons";
import { Button, Card, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { useContext } from "react";
import { CompanyAdminContext } from "../../../shared/providers/company-admin-context";
import AddOpeningModal from "./AddOpeningModal";
import OpeningCard from "./OpeningCard";

export const OpeningsSection = () => {
  const { openings, openModal, isModalOpen, onCloseModal } =
    useContext(CompanyAdminContext);

  return (
    <Card py={10} px={[4, 10]} mt={6} w={"100%"}>
      <Stack gap={6}>
        <HStack alignItems={"flex-start"}>
          <CheckCircleIcon mt={1} color={"primaryBlue.500"} />
          <Text>What are the openings available for our students?</Text>
        </HStack>
        <VStack>
          {openings.map((opening) => (
            <OpeningCard key={opening.title} opening={opening} />
          ))}
        </VStack>
        <HStack>
          <Button
            onClick={() => openModal()}
            variant={"outline"}
            leftIcon={<SmallAddIcon />}
          >
            Add opening
          </Button>
        </HStack>
      </Stack>
      <AddOpeningModal isOpen={isModalOpen} onClose={onCloseModal} />
    </Card>
  );
};

export default OpeningsSection;
