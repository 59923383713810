const variants = {
  outline: {
    field: {
      fontSize:'14px',
      backgroundColor: "lightBg",
      border: '1px solid #d2d2d2',
      borderRadius: '2px',
      // _active: { borderColor: "primaryBlue.300" },
      _focusVisible: {
        borderColor: "primaryBlue.300",
        boxShadow: "0 0 0 1px #7B9EFF",
      },
      _placeholder: {
        color: "gray.300",
        fontSize: "14px",
      },
    },
  },
  filled: {
    field: {
      fontSize:'14px',
      bgColor: "white",
      border: "none",
      borderRadius: '2px',
  
      _hover: {
        bgColor: "#fdfdfd",
      },
      _focusVisible: {
        border: "none",
         bgColor: "white",
        boxShadow: "none",

      },
    },
    _placeholder: {
      color: "gray.300",
      fontSize: "14px",
    },
  },
};

export default { variants };
