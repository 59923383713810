const baseStyle = {
    color: "darkText",

  };

  const variants = {
    primary: {
      color:"primaryBlue.500"
    },
    secondary: {
      color: "white"
    }

  }


  
  export default { baseStyle,variants };
  