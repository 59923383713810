import { Stack, Text } from "@chakra-ui/react";
import { infoStyle, labelStyle } from "../styles/company-page-styles";

type TCardTextWithLabelProps = {
  label: string;
  info: string | number;
};

const OpeningTextWithLabel = ({ label, info }: TCardTextWithLabelProps) => {
  return (
    <Stack
      justify={{ base: "center", lg: "flex-start" }}
      align={{ base: "flex-start", lg: "center" }}
      direction="row"
    >
      <Text variant="secondary" sx={labelStyle}>
        {label}
      </Text>
      <Text variant="secondary" sx={infoStyle}>
        {info}
      </Text>
    </Stack>
  );
};

export default OpeningTextWithLabel;
