import {
  AttachmentIcon,
  CheckCircleIcon,
  DeleteIcon,
  InfoOutlineIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Stack,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { Formik } from "formik";
import { useContext } from "react";
import FormInputField from "../../../components/form-input-field/FormInputField";
import FormTextarea from "../../../components/form-textarea/FormTextarea";
import { CompanyAdminContext } from "../../../shared/providers/company-admin-context";
import {
  companyFormValidationSchema,
  file2Base64,
  setInputMaxCharNo,
  TEXT_AREA_MAX_LENGTH,
  TITLE_MAX_LENGTH,
} from "../company-admin-utils";

export const CompanyInfoForm = () => {
  const { companyInfo, handleSaveCompanyInfo, companyUpdatesLoading } =
    useContext(CompanyAdminContext);

  return (
    <Formik
      initialValues={companyInfo}
      validationSchema={companyFormValidationSchema}
      onSubmit={handleSaveCompanyInfo}
    >
      {({ values, setFieldValue, errors, handleSubmit }) => (
        <form style={{ width: "100%" }} onSubmit={handleSubmit}>
          <Card py={10} px={[6, 10]} mt={10}>
            <Center mb={10}>
              <Heading fontSize={"5xl"} color="darkText">
                Welcome!
              </Heading>
            </Center>
            <HStack alignItems={"flex-start"}>
              <CheckCircleIcon mt={1} color={"primaryBlue.500"} />
              <Text fontSize={"xl"}>
                Knowing your company is very important for our students. Please
                offer some details about your company.
              </Text>
            </HStack>
            <VStack gap={6} mt={10}>
              <Stack w="100%" gap={6} direction={["column", "row"]}>
                <FormInputField
                  label="Company name"
                  placeholder="company name"
                  name="name"
                  value={values.name}
                  handleChange={(e) => {
                    const fieldValue = setInputMaxCharNo(
                      e.target.value,
                      TITLE_MAX_LENGTH
                    );
                    setFieldValue("name", fieldValue);
                  }}
                  isRequired
                  isInvalid={!!errors.name}
                  errorMessage={errors.name}
                />
                <FormInputField
                  label="Recruitment e-mail"
                  placeholder="recruitment e-mail"
                  name="email"
                  value={values.email}
                  handleChange={(e) => {
                    const fieldValue = setInputMaxCharNo(e.target.value, 50);
                    setFieldValue("email", fieldValue);
                  }}
                  isRequired
                  isInvalid={!!errors.email}
                  errorMessage={errors.email}
                />
              </Stack>
              <Stack
                w="100%"
                justifyContent={"center"}
                gap={6}
                alignItems="flex-start"
                direction={["column", null, "row"]}
              >
                <FormTextarea
                  label="Short description"
                  placeholder="short description"
                  name="description"
                  value={values.description}
                  handleChange={(e) => {
                    const fieldValue = setInputMaxCharNo(
                      e.target.value,
                      TEXT_AREA_MAX_LENGTH
                    );
                    setFieldValue("description", fieldValue);
                  }}
                  isRequired
                  isInvalid={!!errors.description}
                  errorMessage={errors.description}
                  h="240px"
                />

                <Stack w="100%">
                  <Text ml={1} fontSize="sm" variant="primary" fontWeight={600}>
                    Company logo
                  </Text>
                  <Box mb={4}>
                    <Button
                      size={"sm"}
                      variant="solid-secondary"
                      leftIcon={<AttachmentIcon />}
                    >
                      Upload
                      <Input
                        type="file"
                        position="absolute"
                        top="0"
                        left="0"
                        opacity="0"
                        aria-hidden="true"
                        accept="image/*"
                        name="logo"
                        onChange={async (ev: any) => {
                          ev.preventDefault();
                          const file = ev.target.files[0];

                          const logo = await file2Base64(file).then(
                            (res) => res
                          );
                          setFieldValue("logo", logo);
                        }}
                      />
                    </Button>
                  </Box>
                  {values.logo ? (
                    <HStack justifyContent={"space-between"}>
                      <Image
                        src={`data:image/png;base64, ${values.logo}`}
                        alt="logo"
                        maxW={"150px"}
                        height={"auto"}
                        objectFit="contain"
                      />
                      <Tooltip label="Remove">
                        <IconButton
                          onClick={() => {
                            setFieldValue("logo", undefined);
                          }}
                          icon={<DeleteIcon />}
                          variant={"link-error"}
                          aria-label={"RemoveLogo"}
                        />
                      </Tooltip>
                    </HStack>
                  ) : null}
                </Stack>
              </Stack>
              <Divider />
              <Flex
                justifyContent={{ base: "space-between" }}
                wrap="wrap"
                w="100%"
                gap={2}
              >
                <Text
                  as="i"
                  color="lightText"
                  alignItems={"center"}
                  display="flex"
                  gap={2}
                >
                  <InfoOutlineIcon />
                  {` `}Note: You need to complete company details before
                  publishing openings.
                </Text>
                <Button type="submit" isLoading={companyUpdatesLoading}>
                  Save changes
                </Button>
              </Flex>
            </VStack>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default CompanyInfoForm;
