import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
} from "@chakra-ui/react";

type FormInputFieldProps = NumberInputProps & {
  value: number | undefined;
  label: string | undefined;
  placeholder?: string;
  forwardedRef?: React.LegacyRef<HTMLInputElement> | undefined;
  errorMessage?: string | undefined;
  isInvalid?: boolean;
  isRequired?: boolean;
  name: string;
  type?: string;
  min?: number;
  defaultValue?: number;
  handleChange: (
    valueAsString: string,
    valueAsNumber: number
  ) => void | undefined;
};

export const FormNumberInputField = ({
  value,
  label,
  forwardedRef,
  errorMessage,
  isInvalid = false,
  isRequired = false,
  name,
  type,
  min,
  max,
  defaultValue,
  handleChange,
  ...rest
}: FormInputFieldProps) => {
  return (
    <FormControl isRequired={isRequired} isInvalid={isInvalid} w="auto">
      <FormLabel
        fontSize={"sm"}
        color={"primaryBlue.500"}
        _invalid={{ color: "error.500" }}
      >
        {label}
      </FormLabel>

      <NumberInput
        min={min}
        max={max}
        name={name}
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        w="70px"
      >
        <NumberInputField pl={3} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      {isInvalid && (
        <FormErrorMessage color="error.500">{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default FormNumberInputField;
