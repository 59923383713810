import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Circle,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { circleStyle, menuButtonStyle } from "../styles/openings-page-styles";
import { keyWords } from "../../company-admin-page/company-admin-utils";

type TOpeningsFilterProps = {
  handleSelectedTags: (selectValues: string | string[]) => void;
  handleClearAllTags: () => void;
  selectedTags: string | string[];
};

const OpeningsFilter = ({
  handleSelectedTags,
  handleClearAllTags,
  selectedTags,
}: TOpeningsFilterProps) => {
  return (
    <Box ml={3}>
      <Menu closeOnSelect={false} isLazy>
        {({ onClose }) => (
          <>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              variant="outline-secondary"
              sx={menuButtonStyle}
            >
              Filter
              {selectedTags.length > 0 ? (
                <Circle sx={circleStyle} size="20px">
                  {selectedTags.length}
                </Circle>
              ) : null}
            </MenuButton>

            <MenuList maxH="400px" overflow={"auto"}>
              <MenuGroup title={undefined}>
                <MenuItem
                  onClick={() => {
                    handleClearAllTags();
                    onClose();
                  }}
                >
                  Clear all
                </MenuItem>
              </MenuGroup>
              <MenuDivider />
              <MenuOptionGroup
                value={selectedTags}
                type="checkbox"
                onChange={handleSelectedTags}
              >
                {keyWords.map((tag) => (
                  <MenuItemOption key={tag} value={tag}>
                    {tag}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  );
};

export default OpeningsFilter;
