import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CompanyCard from "./components/CompanyCard";
import OpeningCard from "./components/OpeningCard";
import { Box, Center, Flex, Spinner, Text } from "@chakra-ui/react";
import {
  headingGradientStyle,
  openingCardsContainer,
} from "./styles/company-page-styles";
import { getCompany } from "../../api/company";
import { TCompany, TOpening } from "./types";

const CompanyPage = () => {
  const [company, setCompany] = useState<TCompany | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { companyId } = useParams();

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      try {
        const response = await getCompany(`${companyId}`);

        if (response.status === 200) {
          setLoading(false);
          setCompany(response.data);
        } else {
          setLoading(false);
          navigate("/404");
        }
      } catch (er) {
        setLoading(false);
        navigate("/404");
      }
    }

    fetchData();
  }, []);

  return (
    <Box minH="calc(100vh - 64px)">
      <Box sx={headingGradientStyle}></Box>
      <Center px={{ base: 6, md: 12 }} mt={-20} flexDirection="column">
        {company && !loading ? (
          <>
            <CompanyCard company={company} />
            {company.openings?.length ? (
              <Flex sx={openingCardsContainer}>
                {company.openings.map((opening: TOpening) => (
                  <OpeningCard opening={opening} key={opening.id} />
                ))}
              </Flex>
            ) : (
              <Text p={6} textAlign="center">
                There are no openings available for the moment.
              </Text>
            )}
          </>
        ) : (
          <Spinner size="xl" color="primaryBlue.500" />
        )}
      </Center>
    </Box>
  );
};

export default CompanyPage;
