

const variants = {
    subtle: {
        container: {
            bg:"gray.500",
            color: "white"
        },
        label: {
            lineHeight: '1.4'
        }
    },
    solid: {
      container: {
        bg: "gray.200",
        color: "gray.800",
      }
    }

  }

  export default {
    variants,
  }
  