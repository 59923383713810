import {
  Card,
  CardBody,
  CardHeader,
  Center,
  Divider,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import { TCompanyProps } from "../types";

const CompanyCard = ({ company }: TCompanyProps) => {
  const { name, logo, description } = company;
  return (
    <Card p={6} h="100%" maxW="700px" minW="300px">
      <CardHeader px={0}>
        <Center flexDirection="column">
          {logo && (
            <Image
              boxSize="70px"
              src={`data:image/jpeg;base64,${logo}`}
              alt={`${name} logo`}
              objectFit={"contain"}
            />
          )}
          <Heading size="lg" textAlign="center" px={6}>
            {name}
          </Heading>
        </Center>
      </CardHeader>
      {description && (
        <CardBody px={0} textAlign="center">
          <Divider color={`grayBg`} />
          <Heading size="md" color={`primaryText`} m={4}>
            About
          </Heading>
          <Text whiteSpace={"pre-line"}>{description}</Text>
        </CardBody>
      )}
    </Card>
  );
};

export default CompanyCard;
