import React, { createContext, FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  addOpening,
  checkCompanyCode,
  fetchCompanyData,
  updateCompanyInfo,
  updateOpening,
  updateOpeningAvailability,
} from "../../api/company-admin";

import {
  companyInitialValues,
  mapResponseToOpenings,
  openingInitialValues,
} from "../../pages/company-admin-page/company-admin-utils";
import { TTrainer } from "../../pages/company-admin-page/types";

import { useToast } from "@chakra-ui/react";

export type TOpeningData = {
  id: string;
  available: boolean;
  title: string | undefined;
  description: string | undefined;
  requirements: string | undefined;
  restrictions: string | undefined;
  recruitmentProcess: string | undefined;
  keyWords: string[];
  customKeyWords: string[];
  hasTechnicalInterview: boolean;
  hasTechnicalTest: boolean;
  startDate: string | undefined;
  periodCount: number;
  periodType: "Week(s)" | "Month(s)";
  openPositions: number;
  acceptOnClosingOpportunity: boolean;
  signAgreement: boolean;
  trainers: TTrainer[];
  company?: {
    description?: string;
    email?: string;
    id: string;
    name: string;
  };
};

export type OpeningResponseType = {
  id: string;
  available: boolean;
  title: string | undefined;
  description: string | undefined;
  requirements: string | undefined;
  restrictions: string | undefined;
  recruitmentProcess: string | undefined;
  keyWords: string[];
  customKeyWords: string[];
  hasTechnicalInterview: boolean;
  hasTechnicalTest: boolean;
  startDate: string | undefined;
  periodCount: number;
  periodType: "WEEKS" | "MONTHS";
  openPositions: number;
  acceptOnClosingOpportunity: boolean;
  signAgreement: boolean;
  trainers: TTrainer[];
  company?: {
    description?: string;
    email?: string;
    id: string;
    name: string;
    logo?: string;
  };
};

export type TCompanyData = {
  id: string;
  name: string | undefined;
  logo: any;
  description: string | undefined;
  email: string | undefined;
};

export interface ICompanyAdminContext {
  openings: TOpeningData[];
  companyInfo: TCompanyData;
  isModalOpen: boolean;
  selectedOpening: TOpeningData | undefined;
  openModal: (opening?: TOpeningData) => void;
  onCloseModal: () => void;
  handleSubmitOpening: (newOpening: TOpeningData) => void;
  onUpdateOpeningAvailability: (id: string, available: boolean) => void;
  handleSaveCompanyInfo: (companyValues: TCompanyData) => void;
  companyUpdatesLoading: boolean;
  publishIsDisabled: boolean;
}

export const CompanyAdminContext = createContext<ICompanyAdminContext>({
  openings: [],
  companyInfo: companyInitialValues,
  isModalOpen: false,
  selectedOpening: undefined,
  openModal: (opening?: TOpeningData) => {},
  onCloseModal: () => {},
  handleSubmitOpening: (newOpening: TOpeningData) => {},
  onUpdateOpeningAvailability: (id: string, available: boolean) => {},
  handleSaveCompanyInfo: (companyValues: TCompanyData) => {},
  companyUpdatesLoading: false,
  publishIsDisabled: false,
});

export const CompanyAdminProvider: FC<{ children: React.ReactNode }> = (
  props
) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get("uuid");
  const code = searchParams.get("code");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedOpening, setSelectedOpening] = useState<
    TOpeningData | undefined
  >(undefined);
  const [openings, setOpenings] = useState<TOpeningData[]>([]);
  const [companyInfo, setCompanyInfo] = useState<TCompanyData | undefined>(
    undefined
  );
  const [companyUpdatesLoading, setCompanyUpdatesLoading] =
    useState<boolean>(false);

  const toast = useToast();

  const publishIsDisabled =
    !companyInfo?.description?.length || !companyInfo?.email?.length;

  useEffect(() => {
    async function fetchData() {
      if (id && code) {
        try {
          const response = await checkCompanyCode(id, code);
          if (response.status === 200) {
            const response = await fetchCompanyData(id, code);
            if (response) {
              setCompanyInfo({
                ...response.data,
              });
              setOpenings(mapResponseToOpenings(response.data.openings));
            }
          } else {
            navigate("/404");
          }
        } catch (er) {
          navigate("/404");
        }
      }
    }
    fetchData();
  }, []);

  const openModal = (opening?: TOpeningData) => {
    setSelectedOpening(opening || openingInitialValues);
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOpening(undefined);
  };

  const onUpdateOpeningAvailability = async (
    openingId: string,
    available: boolean
  ) => {
    if (code) {
      const resp = await updateOpeningAvailability(openingId, code, available);
      if (resp) {
        setOpenings(
          openings.map((op) => ({
            ...op,
            available: op.id === openingId ? available : op.available,
          }))
        );
      }
    }
  };

  const handleSaveCompanyInfo = async (companyValues: TCompanyData) => {
    if (companyInfo?.id && code) {
      setCompanyUpdatesLoading(true);
      try {
        const resp = await updateCompanyInfo(
          {
            description: companyValues.description,
            email: companyValues.email,
            id: companyValues.id,
            logo: companyValues.logo,
            name: companyValues.name,
          },
          code
        );
        setCompanyInfo({ ...resp.data });
        setCompanyUpdatesLoading(false);
        toast({
          title: "Success!",
          description: "The data has been successfully saved!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (er) {
        setCompanyUpdatesLoading(false);
        toast({
          title: "Update failed!",
          description: "Company name or recruitment e-mail already exists!",
          status: "error",
          duration: 10000,
          isClosable: true,
        });
      }
    }
  };

  const handleSubmitOpening = async (newOpening: TOpeningData) => {
    if (companyInfo?.id && code) {
      if (newOpening.id === "new-opening") {
        const resp = await addOpening(
          companyInfo?.id,
          {
            ...newOpening,
            id: undefined,
            available: false,
            periodType:
              newOpening.periodType === "Month(s)" ? "MONTHS" : "WEEKS",
            trainers: [...newOpening.trainers].map((tr) => {
              return {
                name: tr.name,
                linkedinURL: tr.linkedinURL?.trim().length
                  ? tr.linkedinURL
                  : undefined,
                role: tr.role,
                avatar: tr.avatar,
                id: undefined,
              };
            }),
          },
          code
        );
        setOpenings([
          ...openings,
          {
            ...newOpening,
            id: resp.data.id,
            trainers: [...resp.data.trainers],
          },
        ]);
        toast({
          title: "Success!",
          description: "The data has been successfully saved!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        const resp = await updateOpening(
          {
            ...newOpening,
            periodType:
              newOpening.periodType === "Month(s)" ? "MONTHS" : "WEEKS",
            trainers: [...newOpening.trainers].map((tr) => {
              return {
                name: tr.name,
                id: typeof tr.id === "number" ? undefined : tr.id,
                linkedinURL: tr.linkedinURL?.trim().length
                  ? tr.linkedinURL
                  : undefined,
                role: tr.role,
                avatar: tr.avatar,
              };
            }),
          },
          code
        );

        const updatedOpeningIndex = openings.findIndex(
          (op) => op.id === resp.data.id
        );
        const updatedOpenings = openings.map((op, index) => {
          if (index === updatedOpeningIndex) {
            return { ...newOpening, trainers: [...resp.data.trainers] };
          }
          return op;
        });

        setOpenings(updatedOpenings);
        toast({
          title: "Success!",
          description: "The data has been successfully saved!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      onCloseModal();
    }
  };

  return (
    <CompanyAdminContext.Provider
      value={{
        openings,
        companyInfo: companyInfo || companyInitialValues,
        isModalOpen,
        selectedOpening,
        openModal,
        onCloseModal,
        handleSubmitOpening,
        onUpdateOpeningAvailability,
        handleSaveCompanyInfo,
        companyUpdatesLoading,
        publishIsDisabled,
      }}
    >
      {companyInfo ? props.children : null}
    </CompanyAdminContext.Provider>
  );
};
