import {
  Center,
  Box,
  Flex,
  GridItem,
  Stack,
  Text,
  HStack,
  Button,
  Card,
  Image,
  Grid,
  Heading,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HomeIllustration } from "../../assets/HomeIllustration";

import { Link } from "react-router-dom";
import { getCompanies } from "../../api/companies";
import { getOpenings } from "../../api/openings";
import { TCompany } from "../company-page/types";
import { TOpeningData } from "../../shared/providers/company-admin-context";

const MAX_DISPLAYED_OPENINGS = 9;

export const HomePage = () => {
  const [companies, setCompanies] = useState<TCompany[]>([]);
  const [topOpenings, setTopOpenings] = useState<TOpeningData[]>([]);
  const [openingsCount, setOpeningsCount] = useState<number>(0);

  useEffect(() => {
    async function fetchCompanies() {
      const response = await getCompanies();
      if (response) {
        setCompanies(response.data);
      }
    }
    async function fetchOpenings() {
      const response = await getOpenings(MAX_DISPLAYED_OPENINGS);
      if (response) {
        setOpeningsCount(response.data?.totalCount || 0);
        setTopOpenings(response.data.openings);
      }
    }
    fetchOpenings();
    fetchCompanies();
  }, []);

  return (
    <Stack>
      {/* BANNER */}
      <Center
        sx={{
          h: "calc(100vh - 64px)",
          bg: "darkGrayBackground",
          bgGradient:
            "linear-gradient(90deg, rgba(23,24,27,1) 0%, rgba(44,45,51,1) 68%)",

          pt: [0, 4, 12, 12, 20],
          pb: 20,
          px: [4, 12, 12, 12, 40, 60],
        }}
      >
        <Flex gap={20}>
          <Stack gap={10}>
            <Text fontSize={{ base: "3xl", md: "4xl" }} color="white">
              Discover your passion and create your career
            </Text>
	    <Text fontSize={{ base: "xl", md: "2xl" }} color="white">
              Internship positions within the companies with which the Faculty of Automatic Control and Computer Engineering TUIASI collaborates.
            </Text>
            <HStack gap={10}>
              <Stack pr={{ md: 8 }} justify="center" align="center">
                <Text
                  variant="secondary"
                  fontWeight={700}
                  fontSize={{ base: "md", sm: "2xl" }}
                >
                  {openingsCount}
                </Text>
                <Text variant="secondary" fontSize="lg" fontWeight="bold">
                  OPENINGS
                </Text>
              </Stack>
              <Stack px={{ md: 8 }} justify="center" align="center">
                <Text
                  variant="secondary"
                  fontWeight={700}
                  fontSize={{ base: "md", sm: "2xl" }}
                >
                  {companies.length}
                </Text>
                <Text variant="secondary" fontSize="lg" fontWeight="bold">
                  PARTNERS
                </Text>
              </Stack>
            </HStack>
          </Stack>
          <Box display={["none", null, null, "flex"]}>
            <HomeIllustration />
          </Box>
        </Flex>
      </Center>
      {/* TOP 8 OPENINGS */}
      <Center
        py={20}
        //  pt={0}
        px={[2, 12, 12, 12, 40, 60]}
      >
        <Card
          px={[2, 5, 20]}
          pt={10}
          pb={[10, 10, 20]}
          // mt={"-50px"}
          w="100%"
        >
          <Center>
            <Text
              fontSize={"4xl"}
              variant="primary"
              fontWeight={700}
              textAlign="center"
            >
              AVAILABLE OPENINGS
            </Text>
          </Center>
          <Center mb={10}>
            {topOpenings.length ? (
              <Link to="/openings">
                <Button variant="link">{`SEE ALL`}</Button>
              </Link>
            ) : null}
          </Center>

          <Grid
            gap={6}
            flexWrap={"wrap"}
            templateColumns={"repeat(auto-fit, minmax(250px, 1fr))"}
          >
            {topOpenings.length ? (
              topOpenings.map((opening) => (
                <GridItem key={opening.id}>
                  <Link to={`/openings/${opening.id}`}>
                    <Center
                      h="100%"
                      sx={{
                        border: "1px solid #CCC",
                        p: 10,
                        cursor: "pointer",
                        transition: "background-color 0.2s linear",
                      }}
                      _hover={{ bgColor: "#F7F7F7" }}
                    >
                      <Stack>
                        <Text
                          fontSize={"2xl"}
                          fontWeight={700}
                          textAlign="center"
                        >
                          {opening.title}
                        </Text>
                        <Text
                          fontSize="xl"
                          textAlign="center"
                          color="primaryBlue.500"
                        >
                          - {opening.company?.name} -
                        </Text>
                      </Stack>
                    </Center>
                  </Link>
                </GridItem>
              ))
            ) : (
              <Text textAlign={"center"}>
                No openings available yet. Please check later.
              </Text>
            )}
          </Grid>
        </Card>
      </Center>
      {/* PARTNERS */}
      <Center py={20} px={[4, 12, 12, 12, 40, 60]}>
        <Flex
          gap={1}
          flexDirection={["column-reverse", null, null, null, "row"]}
        >
          <Flex
            gap={4}
            flexWrap="wrap"
            flex={companies.length ? 2 : 0}
            justifyContent={["flex-start", "center"]}
          >
            {companies.map((company) => (
              <Link to={`/companies/${company.id}`} key={company.id}>
                <Center
                  sx={{
                    cursor: "pointer",
                    w: "160px",
                    h: "160px",
                    bgColor: "white",
                    p: company.logo ? 3 : 0,
                    borderRadius: "12px",
                    boxShadow: "0px 8px 20px rgba(77, 91, 142, 0.1)",
                    border: "1px solid white",
                    transition:
                      "border-color 0.2s linear, background-color 0.2s linear",
                    _hover: {
                      bgColor: "#F7F7F7",
                      boxShadow: "none",
                      border: "1px solid #CCC",
                    },
                  }}
                >
                  {company.logo ? (
                    <Image
                      src={`data:image/png;base64, ${company.logo}`}
                      alt={company.name}
                    />
                  ) : (
                    <Heading
                      size="md"
                      textAlign="center"
                      p={8}
                      wordBreak="break-word"
                    >
                      {company.name}
                    </Heading>
                  )}
                </Center>
              </Link>
            ))}
          </Flex>
          <Center flex={1} flexDirection="column">
            <Text
              fontSize={"4xl"}
              fontWeight={800}
              color="grayBg"
              textAlign={"center"}
            >
              Meet our partners
            </Text>
            <Link to="/companies">
              <Text
                fontSize={"4xl"}
                fontWeight={800}
                _hover={{
                  backgroundColor: "grayBg",
                  px: 3,
                  borderRadius: "8px",
                }}
              >
                SEE ALL
              </Text>
            </Link>
          </Center>
        </Flex>
      </Center>
    </Stack>
  );
};

export default HomePage;
