import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  Spinner,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TextWithLabel from "./components/TextWithLabel";
import DetailsCard from "./components/DetailsCard";
import DetailsCardsContainer from "./components/DetailsCardsContainer";
import { TOpening } from "./types";
import { getOpening } from "../../api/opening";
import { headingContainerStyle } from "./styles/opening-page-styles";
import { formatDate, formatPeriod } from "../../shared/utils";

const OpeningPage = () => {
  const [opening, setOpening] = useState<TOpening | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { openingId } = useParams();

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      try {
        const response = await getOpening(`${openingId}`);

        if (response.status === 200) {
          setLoading(false);
          setOpening(response.data);
        } else {
          setLoading(false);
          navigate("/404");
        }
      } catch (er) {
        setLoading(false);
        navigate("/404");
      }
    }

    fetchData();
  }, []);

  const tags = opening && [...opening.keyWords, ...opening.customKeyWords];

  return (
    <Box minH="calc(100vh - 64px)">
      <Center sx={headingContainerStyle}>
        {opening && !loading ? (
          <>
            <Heading
              variant="secondary"
              as="h1"
              size="2xl"
              py={6}
              textAlign="center"
            >
              {opening.title}
            </Heading>
            {tags && (
              <Flex py={6} wrap="wrap" justify="center">
                {tags.map((tag, i) => (
                  <Tag size="sm" variant="solid" m="1" key={`${tag}${i}`}>
                    {tag}
                  </Tag>
                ))}
              </Flex>
            )}
            <Divider w="90%" maxW="600px" />
            <Stack py={6} direction={{ base: "column", md: "row" }}>
              <TextWithLabel
                label="START DATE"
                info={formatDate(opening.startDate)}
              />
              <TextWithLabel
                label="OPEN POSITIONS"
                info={opening.openPositions}
              />
              <TextWithLabel
                label="DURATION"
                info={formatPeriod(opening.periodCount, opening.periodType)}
              />
            </Stack>
          </>
        ) : (
          <Spinner size="xl" color="white" />
        )}
      </Center>
      {opening && (
        <>
          <Center mt={-20} px={[4, 12, 12, 12, 40, 80]}>
            <DetailsCard
              title="Description"
              maxWidth="700px"
              isContentCentered={true}
            >
              <Stack gap={2}>
                <Text whiteSpace={"pre-line"}>{opening.description}</Text>
                <Divider />
                <Text align="center" color={`primaryText`}>
                  To apply for this opening, please send your CV at the
                  following email address:{" "}
                  <Text fontWeight="bold" as="span">
                    {opening.company.email}
                  </Text>
                </Text>
              </Stack>
            </DetailsCard>
          </Center>
          <DetailsCardsContainer opening={opening} />
        </>
      )}
    </Box>
  );
};

export default OpeningPage;
