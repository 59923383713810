import * as Yup from "yup";
import {
  OpeningResponseType,
  TOpeningData,
} from "../../shared/providers/company-admin-context";

import { TTrainer } from "./types";

export const TITLE_MAX_LENGTH = 150;
export const TEXT_AREA_MAX_LENGTH = 3000;
export const RECRUITMENT_PROCESS_MAX_LENGTH = 2000;

export const companyInitialValues = {
  id: "",
  name: "",
  logo: undefined,
  logoUrl: undefined,
  description: "",
  email: "",
};

export const openingInitialValues: TOpeningData = {
  id: "new-opening",
  available: false,
  title: "",
  description: "",
  requirements: "",
  restrictions: "",
  recruitmentProcess: "",
  keyWords: [],
  customKeyWords: [],
  hasTechnicalInterview: false,
  hasTechnicalTest: false,
  startDate: undefined,
  periodCount: 1,
  periodType: "Week(s)",
  openPositions: 1,
  acceptOnClosingOpportunity: false,
  signAgreement: false,
  trainers: [],
  company: undefined,
};

export const periodTypes = ["Week(s)", "Month(s)"];

export const keyWords = [
  ".Net",
  ".NET (Core)",
  ".NET Framework",
  "3D Printing",
  "Akka",
  "Analytical skills",
  "Android",
  "Angular",
  "Ant Design",
  "Apache Spark",
  "App developer",
  "Appium",
  "Augmented Reality",
  "Automation Engineer",
  "ASP.NET",
  "AWS",
  "Azure",
  "Backbone.js",
  "Backend",
  "Bitbucket",
  "Blockchain",
  "Bootstrap",
  "CI/CD",
  "CakePHP",
  "Cassandra",
  "ChakraUI",
  "Cloud",
  "Cloud Computing",
  "Cloud technology",
  "Code developer",
  "Communication",
  "Computer programmer",
  "Computer programming",
  "Computer security software",
  "Cucumber",
  "Customer Service",
  "Cybersecurity",
  "Cybersecurity analyst",
  "Cybersecurity attack",
  "Cybersecurity companies",
  "Cybersecurity engineer",
  "Cypress",
  "Data Analysis",
  "Data Science",
  "Design System",
  "Devops",
  "Django",
  "Docker",
  "Dynamic programming",
  "Eclipse",
  "Elasticsearch",
  "Ember",
  "ES6",
  "Express.js",
  "Extended Reality",
  "Finance",
  "Firebase",
  "Flask",
  "Flutter",
  "Frontend",
  "FullStack",
  "GatsBy",
  "Gherkin",
  "Github",
  "Gitlab",
  "Go",
  "Google Cloud Platform (GCP)",
  "GraphQL",
  "Grunt",
  "HTML5 Boilerplate",
  "Headless CMS",
  "Hibernate",
  "IOS",
  "IT manager",
  "IT service management",
  "IT services",
  "Information technology specialist",
  "Infrastructure-as-a-Service (Iaas)",
  "Ionic",
  "Java",
  "Java SE",
  "Java EE",
  "Javascript",
  "Jenkins",
  "JQuery",
  "Kafka",
  "Kubernetes",
  "Laravel",
  "Leadership",
  "LINQ",
  "Managed IT services",
  "Management",
  "MariaDB",
  "Marketing",
  "MaterialUI",
  "Maven",
  "Meteor",
  "Micro frontends",
  "Microsoft Office",
  "Microsoft SQL Server",
  "MongoDB",
  "Monorepo",
  "MySQL",
  "MSSQL",
  "NPM",
  "Nest.js",
  "Network infrastructure",
  "Network security",
  "Next JS",
  "Node.js",
  "Operations",
  "Oracle",
  "PHP",
  "Platform-as-a-Service (Paas)",
  "Play",
  "PostgreSQL",
  "Postman",
  "PowerBI",
  "Programming",
  "Project management",
  "PWA",
  "PyTest",
  "Python",
  "R",
  "React",
  "React JS",
  "React Native",
  "Redis",
  "Redux",
  "Remix",
  "Research",
  "REST/SOAP",
  "Robotic Process Automation (RPA)",
  "Ruby on Rails",
  "Rust",
  "RxJS",
  "Sales",
  "Selenium",
  "Slack",
  "Software developer",
  "Software development",
  "Software-as-a-Service (SaaS)",
  "Spring",
  "Spring Boot",
  "Spring MVC",
  "SQL",
  "SQLite",
  "Struts",
  "Swift (iOS)",
  "Tableau",
  "Teamwork",
  "Tech solutions",
  "Technology consulting",
  "Terraform",
  "Testing",
  "Three JS",
  "Typescript",
  "Virtual Reality",
  "Vue JS",
  "Web",
  "Web designer",
  "Website creation",
  "Xamarin",
];

export const trainerInitialValues: TTrainer = {
  name: "",
  role: "",
  linkedinURL: "",
  avatar: undefined,
  id: undefined,
};

export const companyFormValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Name is required!")
    .min(2, "Name is too short!"),
  description: Yup.string()
    .trim()
    .required("A short description is required!")
    .min(20, "Description is too short!"),
  email: Yup.string()
    .trim()
    .email("Recruitment e-mail is not valid!")
    .required("Recruitment e-mail is required!")
    .min(5, "Recruitment e-mail is not valid!"),
});

export const openingFormValidationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required("Title is required!")
    .min(5, "Title is too short!"),
  description: Yup.string()
    .trim()
    .required("Description is required!")
    .min(20, "Description is too short!"),
  requirements: Yup.string().trim().required("Requirements are required!"),
  restrictions: Yup.string().trim().required("Restrictions are required!"),
  recruitmentProcess: Yup.string()
    .trim()
    .required("Recruitment process is required!")
    .min(10, "Recruitment process is too short!"),
  customKeyWords: Yup.array().of(Yup.string()),
  keyWords: Yup.array()
    .of(Yup.string())
    .when("customKeyWords", (customKeyWords, schema) => {
      if (!customKeyWords[0].length) {
        return schema.min(1, "Please add at least one key word.");
      }
      return schema;
    }),
  hasTechnicalInterview: Yup.boolean(),
  hasTechnicalTest: Yup.boolean(),
  periodCount: Yup.number().max(50, "Period should not exceed 50."),
  periodType: Yup.string().oneOf(periodTypes, "This is not valid!"),
  openPositions: Yup.number().max(50, "Open positions should not exceed 50."),
  acceptOnClosingOpportunity: Yup.boolean().oneOf(
    [true],
    "You need to accept this agreement to proceed."
  ),
  signAgreement: Yup.boolean().oneOf(
    [true],
    "You need to accept this agreement to proceed"
  ),
  trainers: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .trim()
          .required("Name is required!")
          .min(2, "Name is too short!"),
        role: Yup.string().trim().required("Role is required!"),
        linkedinURL: Yup.string().test("isNotEmpty", "Invalid URL", (value) => {
          if (!value) {
            return true;
          }
          return value.length >= 15 && value.length <= 100;
        }),
        avatar: Yup.string(),
        avatarUrl: Yup.string(),
      })
    )
    .min(1, "You need to add at least one trainer for this opening."),
  startDate: Yup.string()
    .required("Start date is required!")
    .test({
      name: "notInThePast",
      test: (value) => {
        if (value) {
          const date = new Date(value);
          const minDate = new Date("2023-06-26");

          return date >= minDate;
        }
        return false;
      },
      message: "Start date can't be before 26th of June 2023",
    }),
});

export const mapResponseToOpenings = (
  openings: OpeningResponseType[]
): TOpeningData[] => {
  return openings.map((opening) => {
    return {
      ...opening,
      periodType: opening.periodType === "MONTHS" ? "Month(s)" : "Week(s)",
    };
  });
};

export const mapResponseToOpening = (
  opening: OpeningResponseType
): TOpeningData => {
  return {
    ...opening,
    periodType: opening.periodType === "MONTHS" ? "Month(s)" : "Week(s)",
  };
};

export const file2Base64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(reader?.result?.toString().replace(/^.*,/, "") || "");

    reader.onerror = (error) => reject(error);
  });
};

export const setInputMaxCharNo = (
  val: string | undefined,
  maxCharNo: number
) => {
  if (val) {
    return val.length >= maxCharNo ? val.slice(0, maxCharNo) : val;
  }
  return "";
};
