import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Center,
  HStack,
  Image,
  PinInput,
  PinInputField,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { authenticateUser } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import AcLogo from "../../assets/acIasi.png";

export const AuthenticatePage = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [authCode, setAuthCode] = useState<string | undefined>(undefined);
  const toast = useToast();

  useEffect(() => {
    localStorage.removeItem("ppac-auth-code-admin");
  }, []);

  const handleSubmit = async () => {
    if (authCode?.trim().length === 6) {
      setLoading(true);
      const data = await authenticateUser(authCode);
      if (data?.status === 200) {
        setLoading(false);
        localStorage.setItem("ppac-auth-code-admin", authCode);
        navigate("/ppac-admin");
      } else {
        setLoading(false);
        setAuthCode("");
        toast({
          title: "Code is incorrect!",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Center h={"calc(100vh - 206px)"}>
      <Card px={[4, 20]} py={16}>
        <Stack gap={6} alignItems="center">
          <Center gap={2}>
            <Image
              src={AcLogo}
              alt="logo"
              height={"40px"}
              objectFit="contain"
            />
            <Text variant="primary" fontSize={"4xl"} fontWeight={700}>
              PPAC
            </Text>
          </Center>

          <Stack spacing={4} alignItems="center">
            <Text ml={1} fontSize="md" color="primaryBlue.500" fontWeight={500}>
              Paste or type your authentication code
            </Text>

            <HStack>
              <PinInput
                mask
                size={["md", "lg"]}
                placeholder="0"
                value={authCode}
                onChange={(val: string) => {
                  setAuthCode(val);
                }}
              >
                <PinInputField _placeholder={{ color: "#c2c2c2" }} />
                <PinInputField _placeholder={{ color: "#c2c2c2" }} />
                <PinInputField _placeholder={{ color: "#c2c2c2" }} />
                <PinInputField _placeholder={{ color: "#c2c2c2" }} />
                <PinInputField _placeholder={{ color: "#c2c2c2" }} />
                <PinInputField _placeholder={{ color: "#c2c2c2" }} />
              </PinInput>
            </HStack>
          </Stack>
          <Button onClick={handleSubmit} isLoading={loading}>
            Sign in
          </Button>
        </Stack>
      </Card>
    </Center>
  );
};

export default AuthenticatePage;
