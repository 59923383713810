const variants = {
    outline: {
        fontSize:'14px',
        backgroundColor: "lightBg",
        border: '1px solid #d2d2d2',
        borderRadius: '2px',
        _placeholder: {
            color: "gray.300",
            fontSize: "14px",
          },
        "_focus-visible": {
            borderColor: "primaryBlue.500",
            boxShadow: "0 0 0 1px #2962FF"
        }
 
    },
    filled: {
        fontSize:'14px',
        bgColor: "white",
        border: "none",
        borderRadius: '2px',
        _hover: {
            bgColor: "#fdfdfd"
        },
        _active: {
            bgColor: "white"
        },
        _focus: {
            bgColor: "white"
        }

    },
  };
  
  export default { variants };
  