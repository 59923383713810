import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Image,
  Show,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import AcLogo from "../../assets/acIasi.png";

export const AppHeaderBar = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <Flex
        w="100%"
        bg="darkGrayBackground"
        bgGradient="linear-gradient(90deg, rgba(23,24,27,1) 0%, rgba(44,45,51,1) 68%)"
        align="center"
        justify={"space-between"}
        px={[4, 12, 12, 12, 40, 60]}
      >
        <NavLink to="/">
          <Center gap={2}>
            <Image
              src={AcLogo}
              alt="logo"
              height={"35px"}
              objectFit="contain"
            />
            <Text variant="secondary" fontSize={"2xl"} fontWeight={700}>
              INTERNSHIP
            </Text>
          </Center>
        </NavLink>

        <Show above="sm">
          <HStack as="nav" spacing="4">
            <NavLink to="/companies">
              <Button variant={"link-on-blue"} h={"64px"}>
                Companies
              </Button>
            </NavLink>
            <NavLink to="/openings">
              <Button variant={"link-on-blue"} h={"64px"}>
                Openings
              </Button>
            </NavLink>
          </HStack>
        </Show>
        <Show below="sm">
          <HStack as="nav" h="64px">
            <IconButton
              onClick={onToggle}
              color="white"
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </HStack>
        </Show>
      </Flex>
      {isOpen ? (
        <Box
          pb={4}
          display={{ sm: "none" }}
          bg="darkGrayBackground"
          bgGradient="linear-gradient(90deg, rgba(23,24,27,1) 0%, rgba(44,45,51,1) 68%)"
          sx={{ borderBottom: "1px solid black" }}
          // bg={`primaryBlue.500`}
          // bgGradient="linear(to-r, primaryBlue.500, primaryBlue.700)"
        >
          <Stack as={"nav"} spacing={4}>
            <NavLink to="/companies" style={{ textAlign: "end" }}>
              <Button variant={"link-on-blue"}>Companies</Button>
            </NavLink>
            <NavLink to="/openings" style={{ textAlign: "end" }}>
              <Button variant={"link-on-blue"}>Openings</Button>
            </NavLink>
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

export default AppHeaderBar;
