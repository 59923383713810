import {
  Button,
  Card,
  Flex,
  Heading,
  Image,
  Stack,
  Tag,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { formatDate, formatPeriod } from "../../../shared/utils";
import { mergeKeyWords } from "../company-page-utils";
import { openingCardStyle } from "../styles/company-page-styles";
import { OpeningCardProps } from "../types";
import OpeningTextWithLabel from "./OpeningTextWithLabel";

const OpeningCard = ({ opening, logo }: OpeningCardProps) => {
  const { id, title, startDate, openPositions, periodCount, periodType } =
    opening;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/openings/${id}`);
  };

  const displayedTags =
    useBreakpointValue({
      base: 2,
      sm: 3,
      md: 4,
      lg: 6,
      xl: 8,
    }) || 3;

  const tags = mergeKeyWords(opening);

  const displayedKeyWords = tags.slice(0, displayedTags);
  const hiddenKeywords = tags.length - displayedKeyWords.length;

  return (
    <Card sx={openingCardStyle}>
      <Flex direction={{ base: "column", md: "row" }} align="center">
        {logo && (
          <Image
            boxSize={{ base: "50px", md: "80px" }}
            mr={{ base: 0, md: 6 }}
            src={`data:image/jpeg;base64,${logo}`}
            alt="company-logo"
            objectFit={"contain"}
          />
        )}
        <Flex direction="column">
          <Heading size="md" color={`primaryText`}>
            {title}
          </Heading>
          <Flex
            mt={6}
            wrap="wrap"
            justify={{ base: "center", md: "flex-start" }}
          >
            {displayedKeyWords.map((keyword, i) => (
              <Tag
                size="sm"
                variant="solid"
                mr="2"
                mb="2"
                key={`${keyword}${i}`}
              >
                {keyword}
              </Tag>
            ))}
            {tags.length > displayedTags ? (
              <Tag
                size="sm"
                variant="solid"
                mr="2"
                mb="2"
              >{`+${hiddenKeywords} more`}</Tag>
            ) : null}
          </Flex>
          <Stack
            pt={5}
            direction={{ base: "column", md: "row" }}
            spacing={{ base: 2, md: 4, lg: 20 }}
          >
            <OpeningTextWithLabel
              label="Start date:"
              info={formatDate(startDate)}
            />
            <OpeningTextWithLabel
              label="Open positions:"
              info={openPositions}
            />
            <OpeningTextWithLabel
              label="Duration:"
              info={formatPeriod(periodCount, periodType)}
            />
          </Stack>
        </Flex>
        <Button
          ml={{ md: "auto" }}
          mt={{ base: 3, md: 0 }}
          onClick={handleClick}
        >
          Details
        </Button>
      </Flex>
    </Card>
  );
};

export default OpeningCard;
