import { EditIcon, LockIcon, UnlockIcon } from "@chakra-ui/icons";
import { useContext } from "react";
import {
  Button,
  Card,
  Flex,
  Heading,
  HStack,
  Stack,
  Tag,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  CompanyAdminContext,
  TOpeningData,
} from "../../../shared/providers/company-admin-context";

export const OpeningCard = ({ opening }: { opening: TOpeningData }) => {
  const { openModal, onUpdateOpeningAvailability, publishIsDisabled } =
    useContext(CompanyAdminContext);

  const displayedTags =
    useBreakpointValue({
      base: 2,
      sm: 3,
      md: 4,
      lg: 6,
      xl: 8,
    }) || 3;
  const mergedKeyWords = [...opening.keyWords, ...opening.customKeyWords];
  const displayedKeyWords = mergedKeyWords.slice(0, displayedTags);
  const hiddenKeywords = mergedKeyWords.length - displayedKeyWords.length;

  return (
    <Card
      px={[4, null, 10]}
      py={4}
      w="100%"
      sx={{ boxShadow: "none", border: "1px solid #e1e1e1" }}
    >
      <Flex
        justifyContent={["flex-start", "space-between"]}
        alignItems={["flex-start", null, "center"]}
        direction={["column", null, "row"]}
        gap={[6, null, 4]}
      >
        <Stack>
          <Heading
            variant="primary"
            fontWeight={500}
            fontSize={["18px", null, "30px"]}
          >
            {opening.title}
          </Heading>
          <HStack alignItems={"flex-end"} flexWrap="wrap" rowGap={2}>
            {displayedKeyWords.map((keyWord, i) => (
              <Tag key={`${keyWord}${i}`}>{keyWord}</Tag>
            ))}
            {mergedKeyWords.length > displayedTags ? (
              <Tag>{`+${hiddenKeywords} more`}</Tag>
            ) : null}
          </HStack>
        </Stack>
        <Stack direction={["row", null, "column"]}>
          <Button
            size="sm"
            variant="outline"
            leftIcon={<EditIcon />}
            onClick={() => openModal(opening)}
          >
            Edit
          </Button>
          <Tooltip
            label={
              !opening.available && publishIsDisabled
                ? "Add company details before publishing an opening"
                : undefined
            }
          >
            <Button
              size="sm"
              variant="outline"
              isDisabled={!opening.available && publishIsDisabled}
              onClick={() =>
                onUpdateOpeningAvailability(opening.id, !opening.available)
              }
              leftIcon={opening.available ? <LockIcon /> : <UnlockIcon />}
            >
              {opening.available ? "Make draft" : "Publish"}
            </Button>
          </Tooltip>
        </Stack>
      </Flex>
    </Card>
  );
};

export default OpeningCard;
