import React from "react";
import { AttachmentIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { getIn, useFormikContext } from "formik";
import FormInputField from "../../../components/form-input-field/FormInputField";
import { TOpeningData } from "../../../shared/providers/company-admin-context";
import { file2Base64, setInputMaxCharNo } from "../company-admin-utils";
import { TrainerCardProps } from "../types";

export const TrainerCard = ({ index, onRemoveTrainer }: TrainerCardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<any>();

  const { values, handleBlur, setFieldValue, errors, touched } =
    useFormikContext<TOpeningData>();
  const nameError = getIn(errors, `trainers[${index}].name`);
  const nameTouched = getIn(touched, `trainers[${index}].name`);
  const roleError = getIn(errors, `trainers[${index}].role`);
  const roleTouched = getIn(touched, `trainers[${index}].role`);
  const linkedinURLError = getIn(errors, `trainers[${index}].linkedinURL`);
  const linkedinURLTouched = getIn(touched, `trainers[${index}].linkedinURL`);

  return (
    <Card
      sx={{
        backgroundColor: "lightBg",
        borderRadius: "4px",
        p: 4,
        position: "relative",
      }}
    >
      <Tooltip label="Remove trainer">
        <IconButton
          sx={{
            position: "absolute",
            top: "-15px",
            right: "-15px",
            backgroundColor: "error.500",
            color: "white",
            _hover: {
              color: "white",
              backgroundColor: "error.600",
            },
          }}
          onClick={onOpen}
          icon={<DeleteIcon />}
          variant={"link"}
          aria-label={"Remove Trainer"}
        />
      </Tooltip>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Remove trainer?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to remove this trainer?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              variant={"outline"}
              colorScheme="error"
              onClick={onClose}
            >
              No
            </Button>
            <Button
              colorScheme="error"
              ml={3}
              onClick={() => {
                onRemoveTrainer();
                onClose();
              }}
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Grid gap={6} templateColumns={"repeat(2, 1fr)"}>
        {/* name */}
        <GridItem w="100%" colSpan={[2, null, 1]}>
          <FormInputField
            label={"Name"}
            variant="filled"
            isRequired
            name={`trainers[${index}].name`}
            isInvalid={!!(nameError && nameTouched)}
            placeholder="name"
            value={values.trainers[index].name}
            handleChange={(e) => {
              const fieldValue = setInputMaxCharNo(e.target.value, 50);
              setFieldValue(`trainers[${index}].name`, fieldValue);
            }}
            onBlur={handleBlur}
            errorMessage={nameError}
          />
        </GridItem>
        {/* Role*/}
        <GridItem w="100%" colStart={[1, null, 2]} colSpan={[2, null, 1]}>
          <FormInputField
            label="Role"
            variant="filled"
            isRequired
            name={`trainers[${index}].role`}
            isInvalid={!!(roleError && roleTouched)}
            placeholder="role"
            errorMessage={roleError}
            value={values.trainers[index].role}
            handleChange={(e) => {
              const fieldValue = setInputMaxCharNo(e.target.value, 150);
              setFieldValue(`trainers[${index}].role`, fieldValue);
            }}
            onBlur={handleBlur}
          />
        </GridItem>

        {/* linkedinProfile */}
        <GridItem w="100%" rowStart={[4, null, 2]} colSpan={[2, null, 1]}>
          <FormInputField
            label={"LinkedIn profile"}
            variant="filled"
            name={`trainers[${index}].linkedinURL`}
            placeholder="linkedIn profile"
            value={values.trainers[index].linkedinURL || undefined}
            handleChange={(e) => {
              const fieldValue = setInputMaxCharNo(e.target.value, 100);
              setFieldValue(`trainers[${index}].linkedinURL`, fieldValue);
            }}
            onBlur={handleBlur}
            isInvalid={!!(linkedinURLError && linkedinURLTouched)}
            errorMessage={linkedinURLError}
          />
        </GridItem>
        {/* avatar */}
        <GridItem
          w="100%"
          display={"flex"}
          rowStart={[4, null, 2]}
          colSpan={[2, null, 1]}
          colStart={[1, null, 2]}
        >
          <Stack spacing={2} w="100%">
            <Text ml={1} fontSize="sm" fontWeight={600}>
              Avatar
            </Text>
            <Box>
              <Button
                size={"sm"}
                variant="solid-secondary"
                leftIcon={<AttachmentIcon />}
              >
                Upload
                <Input
                  type="file"
                  position="absolute"
                  top="0"
                  left="0"
                  opacity="0"
                  aria-hidden="true"
                  accept="image/*"
                  name={`trainers[${index}].avatar`}
                  onChange={async (ev: any) => {
                    ev.preventDefault();
                    const file = ev.target.files[0];

                    const avatar = await file2Base64(file).then((res) => res);
                    setFieldValue(`trainers[${index}].avatar`, avatar);
                  }}
                />
              </Button>
            </Box>
          </Stack>
          {values.trainers[index].avatar ? (
            <HStack>
              <Avatar
                src={`data:image/png;base64, ${values.trainers[index].avatar}`}
                size="md"
              />
              <Tooltip label="Remove">
                <IconButton
                  onClick={() => {
                    setFieldValue(`trainers[${index}].avatar`, undefined);
                  }}
                  icon={<DeleteIcon />}
                  variant={"link-error"}
                  aria-label={"Toggle Navigation"}
                />
              </Tooltip>
            </HStack>
          ) : null}
        </GridItem>
      </Grid>
    </Card>
  );
};

export default TrainerCard;
