export const formatShortDate = (dateValue: string | undefined) => {
  if (dateValue) {
    const date = new Date(dateValue);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    const displayedMonth = month < 10 ? `0${month}` : `${month}`;
    const displayedDay = day < 10 ? `0${day}` : `${day}`;

    return `${displayedDay}-${displayedMonth}-${year}`;
  }
  return undefined;
};

export const formatDateFromResponse = (dateValue: string | undefined) => {
  if (dateValue) {
    const date = new Date(dateValue);

    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    const displayedMonth = month < 10 ? `0${month}` : `${month}`;
    const displayedDay = day < 10 ? `0${day}` : `${day}`;

    return `${year}-${displayedMonth}-${displayedDay}`;
  }
  return undefined;
};

export const formatPeriod = (periodCount: number, periodType: string) => {
  const adjustedPeriodType = periodType.slice(0, -1);
  return periodCount === 1
    ? `${periodCount} ${adjustedPeriodType.toLowerCase()}`
    : `${periodCount} ${periodType.toLowerCase()}`;
};

export const formatDate = (date: string) => {
  return date.split("-").reverse().join("-");
};
