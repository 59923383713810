import { extendTheme, type ThemeConfig, Colors } from "@chakra-ui/react";
import * as components from "./components";
import * as foundations from "./foundations";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const colors: Colors = {
  primaryBlue: {
    50: "#E1E9FF",
    100: "#CCDAFF",
    200: "#A3BCFF",
    300: "#7B9EFF",
    400: "#5280FF",
    500: "#2962FF", // main
    600: "#0040F0",
    700: "#0031B8",
    800: "#002280",
    900: "#001348",
  },
  darkBlue: {
    50: "#C3D3F2",
    100: "#B3C7EE",
    200: "#91AFE7",
    300: "#7096DF",
    400: "#4E7ED8",
    500: "#2E66D0",
    600: "#2655AE",
    700: "#1F458D", // main
    800: "#152E5F",
    900: "#0B1831",
  },
  darkGrayBackground: "#2C2D33",

  white: "#FFFFFF",
  transparent: "transparent",
  black: "#000",
  lightBg: "#F9F9F9",
  grayBg: "#D2D2D2",
  primaryText: "#2962FF",
  darkText: "#42526E",
  lightText: "rgba(66,82,110,.32)",
  error: {
    50: '#F6DCDF',
    100: '#F2CBD0',
    200: '#EAABB3',
    300: '#E18A96',
    400: '#D96A78',
    500: '#D1495B',
    600: '#B42E3F',
    700: '#882230',
    800: '#5B1720',
    900: '#2E0C10',
    950: '#180608'
  }
};

const theme = extendTheme({
  config,
  colors,
  ...foundations,
  components: { ...components },
});

export default theme;
