import { Center, VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CompanyAdminProvider } from "../../shared/providers/company-admin-context";
import CompanyInfoForm from "./form-sections/CompanyInfoForm";
import OpeningsSection from "./form-sections/OpeningsSection";

export const CompanyAdminPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get("uuid");
  const code = searchParams.get("code");

  useEffect(() => {
    if (!id || !code) {
      navigate("/");
    }
  }, []);

  return (
    <CompanyAdminProvider>
      <Center px={[4, 12, 12, 12, 40, 60]}>
        <VStack gap={6} mb={10} w="100%">
          <CompanyInfoForm />
          <OpeningsSection />
        </VStack>
      </Center>
    </CompanyAdminProvider>
  );
};
export default CompanyAdminPage;
