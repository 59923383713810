import axios from "axios";

export const authenticateUser = async (authCode: string) => {
  try {
    const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/codes/checkAdminCode`, { 
      headers: {
        AccessCode: authCode, 
      },

    });
    return data;
  }
  catch(eer) { }
};
