export const gridStyle = {
  gridGap: 6,
  py: { base: 6, md: 12 },
  px: [4, 12, 12, 12, 40, 60],
  w: "100%",
};

export const cardStyle = {
  cursor: "pointer",
  p: 6,
  h: "100%",
  minH: "150px",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid white",
  transition: "border-color 0.2s linear, background-color 0.2s linear",

  _hover: {
    borderColor: "#CCC",
    bgColor: "#F7F7F7",
    boxShadow: "none",
  },
};
