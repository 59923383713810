import {
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Image,
} from "@chakra-ui/react";

type TDetailsCardProps = {
  title: string;
  children: JSX.Element | JSX.Element[];
  isContentCentered?: boolean;
  imageSrc?: string;
  maxWidth?: string;
  height?: string;
};

const DetailsCard = ({
  title,
  children,
  isContentCentered,
  imageSrc,
  maxWidth,
  height = "100%",
}: TDetailsCardProps) => {
  return (
    <Card
      px={6}
      h={height}
      maxWidth={maxWidth}
      textAlign={isContentCentered ? "center" : "initial"}
    >
      <CardHeader px={0}>
        <Flex
          align="center"
          justify={isContentCentered ? "center" : "space-between"}
        >
          <Heading size="md" color={`primaryText`}>
            {title}
          </Heading>
          {imageSrc && (
            <Image
              boxSize="100px"
              height={"35px"}
              src={imageSrc}
              alt="company-logo"
              objectFit={"contain"}
            />
          )}
        </Flex>
      </CardHeader>
      {!isContentCentered && <Divider color={`grayBg`} />}
      <CardBody px={0}>{children}</CardBody>
    </Card>
  );
};

export default DetailsCard;
