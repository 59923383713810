import { StyleFunctionProps, theme } from "@chakra-ui/react";

const variants = {
  outline: (props: StyleFunctionProps) => ({
    ...theme.components.Button.variants?.outline(props),
    borderColor: `${props.colorScheme}.500`,
    color: `${props.colorScheme}.500`,
    lineHeight: 1,
    _hover: {
      bg: `${props.colorScheme}.500`,
      color: "white",
      borderColor: `${props.colorScheme}.500`,
    },
    _active: { bg: `${props.colorScheme}.700`, color: "white" },
    _disabled: {"&:hover": { color: `${props.colorScheme}.500` } }
  }),
  solid: (props: StyleFunctionProps) => ({
    ...theme.components.Button.variants?.solid(props),
    bg: `${props.colorScheme}.500`,
    color: "white",
    lineHeight: 1,
    _hover: {
      bg: `${props.colorScheme}.600`,
    },
    _active: {
      bg: `${props.colorScheme}.700`,
    },
  }),
  "solid-secondary": (props: StyleFunctionProps) => ({
    ...theme.components.Button.variants?.solid(props),
    bg: `gray.100`,
    lineHeight: 1,
    color: "gray.800",
    _hover: {
      bg: `gray.200`,
    },
    _active: {
      bg: `gray.300`,
    },
  }),
  "outline-secondary": (props: StyleFunctionProps) => ({
    ...theme.components.Button.variants?.outline(props),
    fontSize:'14px',
    bg: "lightBg",
    color: "#1A202C",
    border: '1px solid #D2D2D2',
 
    borderRadius: '2px',
    lineHeight: 1,
    _hover: {
      bg: "lightBg",
      borderColor: `gray.300`,
    },
    '_focus-visible': {
      bg: "lightBg",
      borderColor: `${props.colorScheme}.300`,
    },
    _active: {  bg: "lightBg", },
  }),
  link: (props: StyleFunctionProps) => ({
    ...theme.components.Button.variants?.link(props),
    bg: `transparent`,
    color: `${props.colorScheme}.500`,
    padding: "0 16px",
    minWidth: 10,
    height: 10,
    lineHeight: 1,

    _hover: {
      bg: `${props.colorScheme}.50`,
      textDecoration: "none",
    },
  }),
  "link-error": (props: StyleFunctionProps) => ({
    ...theme.components.Button.variants?.link(props),
    bg: `transparent`,
    color: `error.500`,
    padding: "0 16px",
    minWidth: 10,
    height: 10,
    lineHeight: 1,

    _hover: {
      bg: `error.50`,
      textDecoration: "none",
    },
  }),
  "link-on-blue": (props: StyleFunctionProps) => ({
    ...theme.components.Button.variants?.link(props),
    bg: `transparent`,
    color: `white`,
    padding: "0 16px",
    minWidth: 10,
    height: 10,
    lineHeight: 1,
    borderRadius: 0,
    _active: {
      color: `white`,
      bg: `rgba(23,24,27,1)`,
    },
  }),
};

const defaultProps = {
  colorScheme: "primaryBlue",
};

export default { variants, defaultProps };
