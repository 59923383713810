import axios from "axios";
import { OpeningResponseType } from "../shared/providers/company-admin-context";


export const checkCompanyCode = async (companyId: string, authCode: string) => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/codes/checkCompanyCode/${companyId}`, {
    headers: {
      AccessCode: authCode
    }
  })
  return response;
}

export const fetchCompanyData = async (id: string, code: string) => {
  const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/companies/${id}?onlyAvailableOpenings=false`); 

  return data;
};

export const updateCompanyInfo = async (company: any, authCode: string) => {
  const data = await axios.put(`${process.env.REACT_APP_BASE_URL}/companies/${company.id}`, {...company} ,{ 
    headers: {
      AccessCode: authCode, 
    }});

  return data;
};

export const addOpening = async (companyId: string, opening: any, authCode: string) => {
  const data = await axios.post(`${process.env.REACT_APP_BASE_URL}/companies/${companyId}/openings`, { ...opening}, { 
    headers: {
      AccessCode: authCode, 
    }}); 

  return data;
};

export const updateOpening = async (opening: OpeningResponseType, authCode: string) => {
  const data = await axios.put(`${process.env.REACT_APP_BASE_URL}/openings/${opening.id}`, {
    ...opening,            
    }, {
    headers:{
      AccessCode: authCode, 
    }
  })
  return data;
}

export const updateOpeningAvailability = async (openingId: string, authCode: string, available: boolean) => {
  const data = await axios.patch(`${process.env.REACT_APP_BASE_URL}/openings/${openingId}/${available}`, {}, {
    headers:{
      AccessCode: authCode, 
    }
  })
  return data;
}
