import { Stack, Text } from "@chakra-ui/react";

type TTextWithLabelProps = {
  label: string;
  info: string | number;
};

const TextWithLabel = ({ label, info }: TTextWithLabelProps) => {
  return (
    <Stack
      px={{ md: 12 }}
      justify="center"
      align="center"
      direction={{ base: "row-reverse", md: "column" }}
    >
      <Text variant="secondary" fontSize={{ base: "xs", md: "lg" }}>
        {info}
      </Text>
      <Text variant="secondary" fontSize="xs" fontWeight="bold">
        {label}
      </Text>
    </Stack>
  );
};

export default TextWithLabel;
