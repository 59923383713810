import { Center, Flex, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import DetailsCard from "./DetailsCard";
import TrainerDetails from "./TrainerDetails";
import { TOpening, TTrainer } from "../types";
import { CheckIcon } from "@chakra-ui/icons";

const DetailsCardsContainer = ({ opening }: { opening: TOpening }) => {
  const {
    requirements,
    recruitmentProcess,
    restrictions,
    trainers,
    company,
    hasTechnicalInterview,
    hasTechnicalTest,
  } = opening;

  return (
    <Center px={[4, 12, 12, 12, 40, 60]}>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
        }}
        gap={4}
        py={6}
      >
        <GridItem>
          <DetailsCard title="Requirements">
            <Text whiteSpace={"pre-line"}>{requirements}</Text>
          </DetailsCard>
        </GridItem>
        <GridItem order={{ md: 3 }}>
          <DetailsCard title="Recruitment process">
            <Stack gap={2}>
              <Text whiteSpace={"pre-line"}>{recruitmentProcess}</Text>
              <Flex gap={5}>
                {hasTechnicalTest && (
                  <Text color="primaryBlue.500" fontWeight={600}>
                    <CheckIcon /> Technical test included
                  </Text>
                )}
                {hasTechnicalInterview && (
                  <Text color="primaryBlue.500" fontWeight={600}>
                    <CheckIcon /> Technical interview included
                  </Text>
                )}
              </Flex>
            </Stack>
          </DetailsCard>
        </GridItem>
        <GridItem order={{ md: 5 }}>
          <DetailsCard title="Restrictions">
            <Text whiteSpace={"pre-line"}>{restrictions}</Text>
          </DetailsCard>
        </GridItem>
        <GridItem rowSpan={2} order={{ md: 4 }}>
          <DetailsCard title="Trainers" height={"auto"}>
            {trainers.map((trainer: TTrainer) => (
              <TrainerDetails trainer={trainer} key={trainer.id} />
            ))}
          </DetailsCard>
        </GridItem>
        <GridItem order={{ md: 2 }}>
          {company.logo ? (
            <DetailsCard
              title={company.name}
              imageSrc={`data:image/png;base64, ${company.logo} `}
            >
              <Text whiteSpace={"pre-line"}>{company.description}</Text>
            </DetailsCard>
          ) : (
            <DetailsCard title={company.name}>
              <Text whiteSpace={"pre-line"}>{company.description}</Text>
            </DetailsCard>
          )}
        </GridItem>
      </Grid>
    </Center>
  );
};

export default DetailsCardsContainer;
