import axios from "axios";

export const generateCode = async (companyIdentifier: string, authCode:string) => {
  const data = await axios.post(`${process.env.REACT_APP_BASE_URL}/codes/${companyIdentifier}`, {}, { 
    headers: {
     AccessCode: authCode, 
   }});
  return data;
};

export const getCodes = async (authCode:string) => {
  const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/codes`, { 
    headers: {
     AccessCode: authCode, 
   },

 })
  return data;
};

export const removeCompany = async (authCode:string, companyId:string) => {
  const data = await axios.delete(`${process.env.REACT_APP_BASE_URL}/companies/${companyId}`, {
    headers:{
      AccessCode: authCode, 
    }
  })
  return data
}
