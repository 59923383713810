import { useToast } from "@chakra-ui/react";
import React, { createContext, FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { generateCode, getCodes, removeCompany } from "../../api/admin";
import { mapResponseToCompanies } from "../../pages/admin-page/admin-page.utils";
import { TCompanyCode } from "../../pages/admin-page/types";

export interface IAdminContext {
  companies: TCompanyCode[];
  handleGenerateCode: () => void;
  generateLoading: boolean;
  newCompanyIdentifier: string | undefined;
  setNewCompanyIdentifier: (newValue: string) => void;
  onRemoveCompany: (companyId: string) => void;
}

export const AdminContext = createContext<IAdminContext>({
  companies: [],
  handleGenerateCode: async () => {},
  generateLoading: false,
  newCompanyIdentifier: undefined,
  setNewCompanyIdentifier: (newValue: string) => {},
  onRemoveCompany: (companyId: string) => {},
});

export const AdminProvider: FC<{ children: React.ReactNode }> = (props) => {
  const [companies, setCompanies] = useState<TCompanyCode[]>([]);
  const [newCompanyIdentifier, setNewCompanyIdentifier] = useState<
    string | undefined
  >("");
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    async function fetchData(code: string) {
      const response = await getCodes(code);
      if (response && typeof response.data !== "string") {
        setCompanies(mapResponseToCompanies(response.data));
      }
    }
    const authCode = localStorage.getItem("ppac-auth-code-admin");
    if (!authCode) {
      navigate("/ppac-auth");
    } else {
      fetchData(authCode);
    }
  }, []);

  const addCompany = (company: TCompanyCode) => {
    const updatedCompanies = [...companies, company];
    setCompanies(updatedCompanies);
  };

  const handleGenerateCode = async () => {
    const authCode = localStorage.getItem("ppac-auth-code-admin");

    if (
      newCompanyIdentifier &&
      newCompanyIdentifier.trim().length &&
      authCode
    ) {
      setGenerateLoading(true);
      const response = await generateCode(newCompanyIdentifier, authCode).catch(
        (er) => {
          setGenerateLoading(false);
          toast({
            title: `Company identifier ${newCompanyIdentifier} already exists!`,
            description: `Please create a different identifier.`,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      );
      if (response) {
        const {
          data: { accessCode, id },
        } = response;

        addCompany({
          companyIdentifier: newCompanyIdentifier,
          code: accessCode?.value,
          uuid: id,
        });
        setGenerateLoading(false);
        toast({
          title: "Code generated successfully!",
          description: `Code for company with identifier ${newCompanyIdentifier} was created.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setNewCompanyIdentifier("");
      }
    }
  };

  const onRemoveCompany = async (companyId: string) => {
    const authCode = localStorage.getItem("ppac-auth-code-admin");
    if (authCode) {
      const response = await removeCompany(authCode, companyId);
      if (response) {
        const updatedCompanies = companies.filter(
          (company) => company.uuid !== companyId
        );
        setCompanies(updatedCompanies);
      }
    }
  };

  return (
    <AdminContext.Provider
      value={{
        companies,
        handleGenerateCode,
        generateLoading,
        setNewCompanyIdentifier,
        newCompanyIdentifier,
        onRemoveCompany,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};
