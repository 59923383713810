import {
  Button,
  Center,
  Heading,
  Input,
  InputGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useContext } from "react";
import { AdminContext } from "../../../shared/providers/admin-context";

export const AddCompanyTabContent = () => {
  const {
    newCompanyIdentifier,
    handleGenerateCode,
    generateLoading,
    setNewCompanyIdentifier,
  } = useContext(AdminContext);

  return (
    <Center>
      <Stack spacing={5}>
        <Heading textAlign={"center"} mb={4}>
          Add company
        </Heading>
        <Text>
          Create a custom company identifier to generate a unique code.
        </Text>
        <Stack spacing={1}>
          <Text ml={1} fontSize="sm" variant="primary" fontWeight={600}>
            Company identifier
          </Text>
          <InputGroup>
            <Input
              size="lg"
              placeholder="company identifier"
              value={newCompanyIdentifier}
              onChange={(event) =>
                setNewCompanyIdentifier(event.target.value?.slice(0, 150))
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleGenerateCode();
                }
              }}
            />
          </InputGroup>
        </Stack>
        <Button onClick={handleGenerateCode} isLoading={generateLoading}>
          Generate code
        </Button>
      </Stack>
    </Center>
  );
};

export default AddCompanyTabContent;
