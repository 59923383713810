import { TOpening } from "./types";

export const lowerCaseArrayOfStrings = (array: string[]) => {
  return array.map((arrayEl: string) => arrayEl.toLowerCase());
};

export const lowerCaseStrings = (input: string | string[]) => {
  if (Array.isArray(input)) {
    return lowerCaseArrayOfStrings(input);
  } else {
    return input.toLocaleLowerCase();
  }
};

export const mergeKeyWords = (opening: TOpening) => {
  return [...opening.keyWords, ...opening.customKeyWords];
};

export const MAX_NUM_OF_TAGS_DISPLAYED = 5;
