export const headingGradientStyle = {
  bg: "darkGrayBackground",
  bgGradient:
    "linear-gradient(90deg, rgba(23,24,27,1) 0%, rgba(44,45,51,1) 68%)",
  pt: "12",
  pb: "24",
  px: [4, 12, 12, 12, 40, 60],
  flexDirection: "column",
};

export const searchInputStyle = {
  w: "100%",
  maxW: "700px",
};

export const openingCardStyle = {
  p: { base: 6, lg: 8 },
  mb: 6,
  textAlign: { base: "center", md: "initial" },
};

export const openingGridStyle = {
  gridTemplateColumns: {
    base: "repeat(1, 1fr)",
    md: "repeat(2, 1fr)",
  },
  gap: 6,
  w: "100%",
};

export const detailsBtnStyle = {
  ml: { lg: "auto" },
  mt: { base: 3, lg: "auto" },
  alignSelf: { base: "center", lg: "flex-end" },
};

export const circleStyle = {
  bg: "primaryBlue.500",
  color: "white",
  fontSize: "xs",
  position: "absolute",
  top: "-10px",
  right: "-10px",
};

export const menuButtonStyle = {
  maxW: "200px",
  color: "darkText",
  borderColor: "grayBg",
  p: 2,
  px: 4,
  borderRadius: "4px",
  position: "relative",
};
