import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
} from "@chakra-ui/react";

type FormInputFieldProps = InputProps & {
  value: string | undefined;
  label: string | undefined;
  placeholder: string;
  forwardedRef?: React.LegacyRef<HTMLInputElement> | undefined;
  errorMessage?: string | undefined;
  isInvalid?: boolean;
  isRequired?: boolean;
  name: string;
  type?: string;
  handleChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

export const FormInputField = ({
  value,
  label,
  placeholder,
  forwardedRef,
  errorMessage,
  isInvalid = false,
  isRequired = false,
  name,
  type,
  handleChange,
  variant,
  onBlur,
  ...rest
}: FormInputFieldProps) => {
  return (
    <FormControl isRequired={isRequired} isInvalid={isInvalid} maxW={rest.maxW}>
      <FormLabel
        fontSize={"sm"}
        color={variant === "filled" ? "darkText" : "primaryBlue.500"}
        _invalid={{ color: "error.500" }}
      >
        {label}
      </FormLabel>

      <Input
        size="md"
        placeholder={placeholder}
        name={name}
        type={type}
        value={value}
        variant={variant}
        onChange={handleChange}
        ref={forwardedRef}
        onBlur={onBlur}
        _invalid={{ borderColor: "error.500", boxShadow: "0 0 0 1px #D1495B" }}
      />
      {isInvalid && (
        <FormErrorMessage color="error.500">{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default FormInputField;
