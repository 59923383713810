import { Card, Center, Container, Stack } from "@chakra-ui/react";
import { AdminProvider } from "../../shared/providers/admin-context";
import AddCompanyTabContent from "./sections/AddCompanyTabContent";
import CompaniesTabContent from "./sections/CompaniesTabContent";

export const AdminPage = () => {
  return (
    <AdminProvider>
      <Container
        py={10}
        minH={"calc(100vh - 206px)"}
        maxW={{ base: "100%", lg: "80%" }}
      >
        <Stack gap={4}>
          <Center>
            <Card p={10}>
              <AddCompanyTabContent />
            </Card>
          </Center>
          <Card p={[2, 10]}>
            <CompaniesTabContent />
          </Card>
        </Stack>
      </Container>
    </AdminProvider>
  );
};

export default AdminPage;
