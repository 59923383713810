const baseStyle = {
  color: "darkText",
  fontWeight: "400",
};

const variants = {
  light: {
    color: "lightText",
  },
  primary: {
    color: "primaryBlue.500",
  },
  secondary: {
    color: "white",
  },
};

export default { baseStyle, variants };
