import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  AdminPage,
  AuthenticatePage,
  CompaniesPage,
  CompanyAdminPage,
  CompanyPage,
  HomePage,
  OpeningPage,
  OpeningsPage,
  Page404,
} from "./pages";
import AppHeaderBar from "./components/app-header-bar/AppHeaderBar";
import Footer from "./components/footer/Footer";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";

export const App = () => (
  <BrowserRouter>
    <AppHeaderBar />
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="openings/:openingId" element={<OpeningPage />} />
        <Route path="openings" element={<OpeningsPage />} />
        <Route path="companies/:companyId" element={<CompanyPage />} />
        <Route path="companies" element={<CompaniesPage />} />

        <Route path="ppac-auth" element={<AuthenticatePage />} />
        <Route path="ppac-admin" element={<AdminPage />} />
        <Route path="company-admin" element={<CompanyAdminPage />} />

        <Route path="404" element={<Page404 />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </ScrollToTop>
    <Footer />
  </BrowserRouter>
);
