export const headingContainerStyle = {
  bg: "darkGrayBackground",
  bgGradient:
    "linear-gradient(90deg, rgba(23,24,27,1) 0%, rgba(44,45,51,1) 68%)",
  minH: "60vh",
  // bg: "primaryBlue.500",
  // bgGradient: "linear(to-r, primaryBlue.500, primaryBlue.700)",
  color: "white",
  pt: "12",
  pb: "24",
  flexDir: "column",
  px: [4, 12, 12, 12, 40, 80],
};

export const cvIconStyle = {
  color: "darkBlue.50",
  ":hover": {
    color: "primaryBlue.500",
  },
};
