import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Flex,
  Grid,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getOpenings } from "../../api/openings";
import {
  headingGradientStyle,
  openingGridStyle,
  searchInputStyle,
} from "./styles/openings-page-styles";
import {
  lowerCaseArrayOfStrings,
  lowerCaseStrings,
  mergeKeyWords,
} from "./openings-page.utils";
import OpeningsPageCard from "./components/OpeningsPageCard";
import OpeningsFilter from "./components/OpeningsFilter";
import { TOpening } from "./types";

export const OpeningsPage = () => {
  const [openings, setOpenings] = useState<TOpening[]>([]);
  const [filteredOpenings, setFilteredOpenings] = useState<TOpening[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<string | string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      const response = await getOpenings();
      if (response) {
        setOpenings(response.data.openings);
        setFilteredOpenings(response.data.openings);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    let filtered = [...openings];

    if (searchTerm) {
      filtered = filtered.filter((opening) => {
        const tags = mergeKeyWords(opening);
        const searchByTags = lowerCaseArrayOfStrings(tags).find((tag: string) =>
          tag.includes(searchTerm.toLowerCase().trim())
        );

        const searchByName = opening.title
          .toLowerCase()
          .includes(searchTerm.toLowerCase().trim());

        return searchByName || searchByTags;
      });
    }

    if (selectedTags.length > 0) {
      filtered = filtered.filter((opening) => {
        const tags = mergeKeyWords(opening);
        const filteredByTags = tags.some(
          (tag) =>
            lowerCaseStrings(selectedTags).indexOf(tag.toLowerCase().trim()) !==
            -1
        );

        return filteredByTags;
      });
    }

    setFilteredOpenings(filtered);
  }, [searchTerm, openings, selectedTags]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(e.target.value);
  };

  const handleSelectedTags = (selectValues: string | string[]) => {
    setSelectedTags(selectValues);
  };

  const handleClearAllTags = () => {
    setSelectedTags([]);
  };

  return (
    <Box minH="calc(100vh - 64px)">
      <Center sx={headingGradientStyle}>
        <Heading size="xl" color="white" mb={6} textAlign="center">
          Discover our openings
        </Heading>
        <Flex w="100%" maxW="700px" justifyContent="space-between">
          <InputGroup sx={searchInputStyle}>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" transform="scaleX(-1)" />}
            />
            <Input placeholder="Search..." onChange={handleSearch} />
          </InputGroup>
          <OpeningsFilter
            handleSelectedTags={handleSelectedTags}
            handleClearAllTags={handleClearAllTags}
            selectedTags={selectedTags}
          />
        </Flex>
      </Center>
      <Center
        flexDirection="column"
        py={{ base: 6, md: 12 }}
        px={[4, 12, 12, 12, 40, 60]}
      >
        {openings.length > 0 ? (
          <>
            <Grid sx={openingGridStyle}>
              {filteredOpenings.map((opening: TOpening) => (
                <OpeningsPageCard opening={opening} key={opening.id} />
              ))}
            </Grid>
            {filteredOpenings.length === 0 && (
              <Text textAlign="center">
                We couldn't find any matches for your search. Please try
                different keywords or filters.
              </Text>
            )}
          </>
        ) : (
          <>
            {loading ? (
              <Spinner size="xl" mt={6} />
            ) : (
              <Text px={6} textAlign="center">
                There are no openings available for the moment.
              </Text>
            )}
          </>
        )}
      </Center>
    </Box>
  );
};

export default OpeningsPage;
