export const headingGradientStyle = {
  bg: "darkGrayBackground",
  bgGradient:
    "linear-gradient(90deg, rgba(23,24,27,1) 0%, rgba(44,45,51,1) 68%)",
  pt: "12",
  pb: "24",
};

export const infoStyle = {
  fontSize: { base: "xs", lg: "sm" },
  color: "darkText",
};

export const labelStyle = {
  ...infoStyle,
  fontWeight: "bold",
};

export const openingCardsContainer = {
  flexDirection: "column",
  mt: "12",
  w: "100%",
  alignItems: "center",
};

export const openingCardStyle = {
  px: { base: 6, md: 12 },
  py: 6,
  mb: 6,
  textAlign: { base: "center", md: "initial" },
  w: "100%",
  maxW: { base: "auto", md: "1000px" },
};
